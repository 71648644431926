import * as React from "react";
import { useKeycloak } from "@react-keycloak/web"
import "../../../../globals.css"
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks";
import { getProfile } from "../../../../features/profiles/profilesSlice";
import { useEffect } from "react";
import { Navigation } from "../../common/navigation";
import AuthLoading  from "../../common/authLoading";
import { BreadcrumbItem, Breadcrumbs } from "@heroui/react";
import HomeIcon from "../../common/icons/homeIcon";
import { useNavigate } from "react-router-dom";
import PeopleIcon from "../../common/icons/peopleIcon";
import { AdminCandidatesListTable } from "./table";
import { canManageCandidates } from "../../../../lib/user";
import CandidatesListLoading from "./loading";
import CandidatesListError from "./error";

export function AdminCandidatesList() {

    const { keycloak, initialized } = useKeycloak()
    let navigate = useNavigate()

    const dispatch = useAppDispatch()
    const profile = useAppSelector(state => state.profiles.profile)
    const getProfileStatus = useAppSelector(state => state.profiles.getProfileStatus)

    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 

    useEffect(() => {
      if (keycloak.authenticated && !keycloak.userInfo) {

        if(!canManageCandidates(keycloak)) {
          navigate('/')
        }

        if(!profile) {
          dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])

    return initialized && keycloak.authenticated ? (

      <React.Fragment>
        {getProfileStatus === 'loading' && (
          <CandidatesListLoading />
        )}
        {getProfileStatus === 'failed' && (
          <CandidatesListError />
        )}
        {getProfileStatus === 'idle' && profile && (
      
          <div className="bg-zinc-50 min-h-screen">
            <Navigation />

            <div className="ml-5 mr-5 pt-8 sm:container sm:mx-auto">
              <Breadcrumbs className="mt-3 mb-11">
                <BreadcrumbItem onPress={x => navigate('/')}><HomeIcon />Home</BreadcrumbItem>
                <BreadcrumbItem onPress={x => navigate('/admin-candidates')}><PeopleIcon />Candidates</BreadcrumbItem>
                <BreadcrumbItem>List</BreadcrumbItem>
              </Breadcrumbs>

              <AdminCandidatesListTable />

            </div>
          </div>
        )}
      </React.Fragment>
    ) : <AuthLoading />
}
