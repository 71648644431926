import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface FilesSliceState {
  uploadFileStatus: "idle" | "loading" | "failed"
}

const initialState: FilesSliceState = {
  uploadFileStatus: 'idle',
};

export const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state) => {
        state.uploadFileStatus = 'loading'
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.uploadFileStatus = 'idle'
      })
      .addCase(uploadFile.rejected, (state) => {
        state.uploadFileStatus = 'failed' 
      })
  },
})

export const uploadFile = createAsyncThunk(
  'files/uploadFile',
  async (data: any, thunkAPI) => {
      const response = await fetch(`${process.env.REACT_APP_FILES_API_URL}files`, {
      method: "POST",
      body: data.payload.file,
      headers: { "Authorization": "Bearer " + data.token}
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export default filesSlice.reducer;
