import * as React from "react";
import { useKeycloak } from "@react-keycloak/web"
import "../../../globals.css"
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import { calculateCandidatePercentageComplete, isValidUrl, normaliseUrl, translateNoticePeriod, translateWorkType } from "../../../lib/utils";
import { getGeolocation, getProfile } from "../../../features/profiles/profilesSlice";
import { useEffect } from "react";
import { Navigation } from "../common/navigation";
import DashboardLoading  from "./loading";
import DashboardError  from "./error";
import CandidateInvitationCode  from "../candidates/initial/invitationCode";
import InitialCandidateProfileForm from "../candidates/initial/editProfile";
import InitialCandidateInduction from "../candidates/initial/induction";
import { getRoles } from "../../../features/candidates/candidatesSlice";
import { internationalisePhoneNumberForDisplay, shouldInternationalisePhoneNumber } from "../../../lib/phoneNumber";
import { BreadcrumbItem, Breadcrumbs, Card, CardBody, CardHeader, Progress } from "@heroui/react";
import { useNavigate } from "react-router-dom";

import HomeIcon from "../common/icons/homeIcon";
import EditIcon from "../common/icons/editIcon";
import moment from 'moment';
import StarIcon from "../common/icons/starIcon";
import { invitationCodeRequired } from "../../../lib/user";
import StarOutlineIcon from "../common/icons/starOutlineIcon";
import InitialCandidateRoleForm from "../candidates/initial/addRole";

export function CandidateDashboard() {

    const { keycloak, initialized } = useKeycloak()

    const dispatch = useAppDispatch()
    let navigate = useNavigate()

    const profile = useAppSelector(state => state.profiles.profile)
    const getProfileStatus = useAppSelector(state => state.profiles.getProfileStatus)

    const roles = useAppSelector(state => state.candidates.roles)
    const getRolesStatus = useAppSelector(state => state.candidates.getRolesStatus)

    const geolocation = useAppSelector(state => state.profiles.geolocation)

    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 

    useEffect(() => {
      if (keycloak.authenticated && !keycloak.userInfo) {

        if(shouldInternationalisePhoneNumber(keycloak.tokenParsed!.phone_number)) {
          dispatch(getGeolocation())
        }

        if(!profile) {
          dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
            .then((response) => {
              var payload = {token: keycloak.token, uuid: response.payload.uuid}
              dispatch(getRoles(payload))
            })
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated, roles, getRolesStatus])

    return (
      <React.Fragment>
        {(getRolesStatus === 'loading') && (
          <DashboardLoading />
        )}
        {(getRolesStatus === 'failed') && (
          <DashboardError />
        )}
        {getProfileStatus === 'idle' && invitationCodeRequired(keycloak) && !profile?.invited && (
          <CandidateInvitationCode />
        )}
        {getProfileStatus === 'idle' && (profile?.invited || !invitationCodeRequired(keycloak)) && !profile?.completed &&  (
          <InitialCandidateProfileForm />
        )}
        {getProfileStatus === 'idle' && (profile?.invited || !invitationCodeRequired(keycloak)) && profile?.completed && !profile?.candidateProfile?.inducted && (
          <InitialCandidateInduction />
        )}
        {getProfileStatus === 'idle' && (profile?.invited || !invitationCodeRequired(keycloak)) && profile?.completed && profile?.candidateProfile?.inducted && !profile?.candidateProfile?.roleAdded && (
          <InitialCandidateRoleForm />
        )}
        {getProfileStatus === 'idle' && (profile?.invited || !invitationCodeRequired(keycloak)) && profile?.completed && profile?.candidateProfile?.inducted && profile?.candidateProfile?.roleAdded && (
      
          <div className="bg-zinc-50 min-h-screen">
            <Navigation />
            <div className="ml-5 mr-5 pt-8 sm:container sm:mx-auto">

              <Breadcrumbs className="mt-3 mb-11">
                <BreadcrumbItem onPress={x => navigate('/')}><HomeIcon />Home</BreadcrumbItem>
                <BreadcrumbItem>Dashboard</BreadcrumbItem>
              </Breadcrumbs>

              {profile?.candidateProfile?.actions?.updateOpenToWork && (
                <div className="bg-yellow-50 border-l-4 border-yellow-500 text-yellow-800 p-4 rounded-md mb-12 flex justify-between" role="alert">
                  <div className="flex items-center">
                    <div className="pr-4">
                      <svg className="fill-current h-6 w-6 text-yellow-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>
                    </div>
                    <div>
                      <p className="font-bold pb-2">Open to work</p>
                      <p>You haven't let us know whether or not you are currently open to work. <a className="font-bold" href="/add-hiring-team-member" onClick={(e) => { e.preventDefault(); navigate('/edit-my-work')}}>Update your status here</a></p>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex flex-wrap justify-between">

                <div className="mb-6 lg:mb-0 w-full lg:w-1/3">
                  <Card shadow="none">
                    <CardHeader className="px-6 pt-4 pb-0 font-semibold justify-between">
                      {keycloak.tokenParsed?.given_name} {keycloak.tokenParsed?.family_name}
                      <span className="text-lg text-default-900 cursor-pointer active:opacity-50" onClick={() => navigate('/edit-my-profile')}>
                        <EditIcon />
                      </span>
                    </CardHeader>
                    <CardBody className="p-6">

                      <span className="bg-slate-100 p-4 mt-4 mb-8 rounded-md">
                        <span className="text-sm justify-between flex">
                          <span>Your profile is {calculateCandidatePercentageComplete(profile)}% complete</span>
                          {calculateCandidatePercentageComplete(profile) === 100 && <StarIcon fill='gold' />}
                          {calculateCandidatePercentageComplete(profile) < 100 && <StarOutlineIcon />}
                        </span>
                        <Progress size="sm" aria-label="Profile completeness" value={calculateCandidatePercentageComplete(profile)} className="mt-4"/>
                      </span>

                      <span className="text-slate-500">About me</span>
                      <span className="pb-8">{profile?.candidateProfile?.description}</span>

                      <span className="text-slate-500">Email</span>
                      <span className="pb-2">{keycloak.tokenParsed?.email}</span>

                      <span className="text-slate-500">Phone</span>
                      <span className="pb-2">{internationalisePhoneNumberForDisplay(keycloak.tokenParsed?.phone_number, geolocation)}</span>

                      <span className="text-slate-500">Ideal work location</span>
                      <span className="pb-2">{profile?.candidateProfile?.idealWorkLocation?.formatted}</span>

                      <span className="text-slate-500">Date joined</span>
                      <span className="pb-2">{moment(profile?.dateCreated).format('Do MMMM, YYYY')}</span>

                      {profile?.candidateProfile?.linkedInUrl && ( 
                          <>
                            <span className="text-slate-500">LinkedIn</span>
                            <span className="pb-2">
                              {isValidUrl(profile?.candidateProfile?.linkedInUrl) && (<a className="link-primary" href={normaliseUrl(profile?.candidateProfile?.linkedInUrl)} target="_blank" rel="noreferrer">{profile?.candidateProfile?.linkedInUrl}</a>)}
                              {!isValidUrl(profile?.candidateProfile?.linkedInUrl) && (<span>{profile?.candidateProfile?.linkedInUrl}</span>)}
                            </span>
                          </>
                      )}
                      {profile?.candidateProfile?.gitHubUrl && ( 
                          <>
                            <span className="text-slate-500">GitHub</span>
                            <span className="pb-2">
                              {isValidUrl(profile?.candidateProfile?.gitHubUrl) && (<a className="link-primary" href={normaliseUrl(profile?.candidateProfile?.gitHubUrl)} target="_blank" rel="noreferrer">{profile?.candidateProfile?.gitHubUrl}</a>)}
                              {!isValidUrl(profile?.candidateProfile?.gitHubUrl) && (<span>{profile?.candidateProfile?.gitHubUrl}</span>)}
                            </span>
                          </>
                      )}
                    </CardBody>
                  </Card>
                </div>
                <div className="mb-6 lg:mb-0 lg:pl-10 w-full lg:w-2/3">
                  <Card shadow="none" className="mb-10">
                    <CardHeader className="bg-slate-100 font-semibold justify-between p-6 py-4">
                      My roles
                    </CardHeader>
                    <CardBody className="p-6">

                      {roles?.map((value) => (
                        <div className="flex pb-4" key={value.uuid}>
                          <span className="w-5/12">
                            {moment(value.startDate).format('MMMM YYYY')}
                            &nbsp;-&nbsp;
                            {value.endDate === null && <span>Current</span>}
                            {value.endDate !== null && <span>{moment(value.endDate).format('MMMM YYYY')}</span>}
                          </span>
                          <span className="w-7/12">
                            <button className="link-primary" onClick={() => navigate(`/edit-role/${value.uuid}`)}>
                              {value.title.name}
                              &nbsp;-&nbsp;
                              {value.company.name}
                            </button>
                          </span>
                        </div>
                      ))}
    
                    </CardBody>
                  </Card>
                  <Card shadow="none" className="mb-10">
                    <CardHeader className="bg-slate-100 font-semibold justify-between p-6 py-4">
                      My work
                      <span className="text-lg text-default-900 cursor-pointer active:opacity-50" onClick={() => navigate('/edit-my-work')}>
                        <EditIcon />
                      </span>
                    </CardHeader>
                    <CardBody className="p-6">

                      <span className="text-slate-500">Current Day Rate (ex Super &amp; GST)</span>
                      {profile?.candidateProfile?.currentDayRate !== null && <span className="pb-2">{new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(profile?.candidateProfile?.currentDayRate)}</span>}
                      {profile?.candidateProfile?.currentDayRate === null && <span className="pb-2">Not provided</span>}

                      <span className="text-slate-500">Notice Period</span>
                      {profile?.candidateProfile?.noticePeriod != null && <span className="pb-2">{translateNoticePeriod(profile?.candidateProfile?.noticePeriod)}</span>}
                      {profile?.candidateProfile?.noticePeriod === null && <span className="pb-2">Not provided</span>}

                      <span className="text-slate-500">Open to work</span>
                      {profile?.candidateProfile?.openToWork != null && <span className="pb-2">{profile?.candidateProfile?.openToWork ? "Yes" : "No"}</span>}
                      {profile?.candidateProfile?.openToWork === null && <span className="pb-2">Not provided</span>}

                      <span className="text-slate-500 mt-8">Ideal Position</span>
                      {profile?.candidateProfile?.idealPosition && <span className="pb-2">{profile?.candidateProfile?.idealPosition?.name}</span>}
                      {!profile?.candidateProfile?.idealPosition && <span className="pb-2">Not provided</span>}

                      <span className="text-slate-500">Ideal Work Type</span>
                      {profile?.candidateProfile?.idealWorkType && <span className="pb-2">{translateWorkType(profile?.candidateProfile?.idealWorkType)}</span>}
                      {!profile?.candidateProfile?.idealWorkType && <span className="pb-2">Not provided</span>}
                      
                      <span className="text-slate-500">Ideal Day Rate (ex Super &amp; GST)</span>
                      {profile?.candidateProfile?.idealDayRate !== null && <span className="pb-2">{new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(profile?.candidateProfile?.idealDayRate)}</span>}
                      {profile?.candidateProfile?.idealDayRate === null && <span className="pb-2">Not provided</span>}

                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
}
