import * as React from "react";
import { useKeycloak } from "@react-keycloak/web"
import "../../../../globals.css"
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks";
import { getProfile } from "../../../../features/profiles/profilesSlice";
import { useEffect } from "react";
import AuthLoading  from "../../common/authLoading";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { Card, CardBody, CardHeader } from "@heroui/react";
import moment from "moment";
import { canManageCompanies } from "../../../../lib/company";
import CompaniesListLoading from "./loading";
import MoneyIcon from "../../common/icons/moneyIcon";
import MapPinIcon from "../../common/icons/mapPinIcon";
import DurationIcon from "../../common/icons/durationIcon";
import WorkLocationIcon from "../../common/icons/workLocationIcon";
import SuperUserIcon from "../../common/icons/superUserIcon";

export function AdminViewCompanyProfile() {

    const { keycloak, initialized } = useKeycloak()
    let navigate = useNavigate()

    const dispatch = useAppDispatch()
    const profile = useAppSelector(state => state.profiles.profile)

    let { id } = useParams();

    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 

    const fetcher = async function fetcher<JSON = any>(
      input: RequestInfo,
      init?: RequestInit
    ): Promise<JSON> {
      const res = await fetch(input, {
        method: "GET",
        headers: { "Authorization": "Bearer " + keycloak.token, "Content-Type": "application/json" }
      })
      return res.json()
    }
    
    const {data, isLoading} = useSWR<any, string>(`${process.env.REACT_APP_COMPANIES_API_URL}companies/${id}`, fetcher, {
      keepPreviousData: true,
    });

    useEffect(() => {
      if (keycloak.authenticated && !keycloak.userInfo) {

        if(!canManageCompanies(keycloak)) {
          navigate('/')
        }

        if(!profile) {
          dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])

  return initialized && keycloak.authenticated ? (

      <React.Fragment>
        {isLoading && (
          <CompaniesListLoading />
        )}
        {data !== undefined && (

          <div className="flex flex-wrap justify-between">

            <div className="mb-6 lg:mb-0 w-full lg:w-1/3">
              <Card shadow="none">
                <CardHeader className="px-6 pt-6 pb-0 font-semibold justify-between">
                  <span className="bg-slate-100 p-4 rounded-md w-full">
                    <span className="text-sm justify-between flex">
                      <span>
                        <span className="text-slate-500">Company details</span>
                        <span className="block">{data?.name}</span>
                      </span>
                      {data?.logo && (
                        <div>
                          <img className="max-w-24" src={`${process.env.REACT_APP_IMAGE_PATH}${data.logo}`} alt={`${data?.name} logo`} />
                        </div>
                      )}
                    </span>
                  </span>
                </CardHeader>
                <CardBody className="p-6">
                  <span className="text-slate-500">Date joined</span>
                  <span className="pb-2">{moment(data?.dateCreated).format('Do MMMM, YYYY')}</span>
                </CardBody>
              </Card>
            </div>
            <div className="mb-6 lg:mb-0 lg:pl-10 w-full lg:w-2/3">
              <Card shadow="none" className="mb-10">
                <CardHeader className="bg-slate-100 font-semibold justify-between p-6 py-4">
                  Hiring Team
                </CardHeader>
                <CardBody className="p-6">
                  <div className="flex flex-row flex-wrap">
                    {data?.users?.filter((x: any) => !x.archived).map((item: any, index: number) => (
                      <span key={index} className="w-1/3 pr-4 min-h-40">
                          <strong className="pb-2 flex"><span className="pr-2">{item.firstName} {item.lastName}</span>{item.superUser === true && <span><SuperUserIcon /></span>}</strong>
                          {item.visibility.map((vis: any, index2: number) => (
                            <span key={index2}>
                              {vis === 'PAY_RATES' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><MoneyIcon /></span><span className="pl-2 pt-0.5">Pay rates</span></em>}
                              {vis === 'LOCATION' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><MapPinIcon /></span><span className="pl-2 pt-0.5">Location</span></em>}
                              {vis === 'DURATION' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><DurationIcon /></span><span className="pl-2 pt-0.5">Contract duration</span></em>}
                              {vis === 'WORK_LOCATION' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><WorkLocationIcon /></span><span className="pl-2 pt-0.5">Work location</span></em>}
                            </span>
                          ))}
                      </span>
                    ))}
                    </div>
                </CardBody>
              </Card>
              <Card shadow="none" className="mb-10">
                <CardHeader className="bg-slate-100 font-semibold justify-between p-6 py-4">
                  Pending hiring team members
                </CardHeader>
                <CardBody className="p-6">

                  {data?.invitedUsers?.map((item: any, index: number) => (
                    <div className="block">
                      {data?.invitedUsers.map((profile: any, index: number) => (
                          <span key={index} className="block w-1/3 pr-4 min-h-40">
                            <strong className="pb-2 flex"><span className="pr-2">{profile.emailAddress}</span>{profile.superUser === true && <span><SuperUserIcon /></span>}</strong>
                            {profile.visibility.map((vis: any, index2: number) => (
                              <span key={index2} className="block">
                                {vis === 'PAY_RATES' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><MoneyIcon /></span><span className="pl-2 pt-0.5">Pay rates</span></em>}
                                {vis === 'LOCATION' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><MapPinIcon /></span><span className="pl-2 pt-0.5">Location</span></em>}
                                {vis === 'DURATION' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><DurationIcon /></span><span className="pl-2 pt-0.5">Contract duration</span></em>}
                                {vis === 'WORK_LOCATION' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><WorkLocationIcon /></span><span className="pl-2 pt-0.5">Work location</span></em>}
                              </span>
                            ))}
                          </span>
                      ))}
                    </div>
                  ))}
                  {data?.invitedUsers?.length === 0 && <span>No pending invites</span>}

                </CardBody>
              </Card>
            </div>
          </div>
        )}
      </React.Fragment>
    ) : <AuthLoading />
}
