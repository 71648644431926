
import React, { useEffect } from "react";
import { BreadcrumbItem, Breadcrumbs } from "@heroui/react";
import { Navigation } from "../../common/navigation";
import { useNavigate, useParams } from "react-router-dom";
import { parseDate, getLocalTimeZone } from "@internationalized/date";
import HomeIcon from "../../common/icons/homeIcon";
import RolesIcon from "../common/rolesIcon";
import CandidateRoleForm from "./form";
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks";
import { useKeycloak } from "@react-keycloak/web";
import { getProfile } from "../../../../features/profiles/profilesSlice";
import { getRoles, Role } from "../../../../features/candidates/candidatesSlice";

export default function EditCandidateRoleForm() {

    const { keycloak, initialized } = useKeycloak()
    
    const dispatch = useAppDispatch()
    let navigate = useNavigate()

    const profile = useAppSelector(state => state.profiles.profile)
    const getProfileStatus = useAppSelector(state => state.profiles.getProfileStatus)

    const roles = useAppSelector(state => state.candidates.roles)
    const getRolesStatus = useAppSelector(state => state.candidates.getRolesStatus)

    let { id } = useParams();
    
    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 

    useEffect(() => {
        if (keycloak.authenticated) {

            if(!profile) {
                dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
                    .then((response) => {
                        dispatch(getRoles({token: keycloak.token, uuid: response.payload.uuid}))
                })
            } else if(!roles || roles.length === 0) {
                dispatch(getRoles({token: keycloak.token, uuid: profile.uuid}))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])

    var role = roles.find((x: Role) => x.uuid === id);

    return getProfileStatus === 'idle' && getRolesStatus === 'idle' ? (

        <div className="bg-zinc-50 min-h-screen">
            <Navigation />
            <div className="ml-5 mr-5 pt-8 sm:container sm:mx-auto">
        
                <Breadcrumbs className="pt-3 mb-11">
                    <BreadcrumbItem onPress={x => navigate('/')}><HomeIcon />Home</BreadcrumbItem>
                    <BreadcrumbItem onPress={x => navigate('/roles')}><RolesIcon />My roles</BreadcrumbItem>
                    <BreadcrumbItem>Edit role</BreadcrumbItem>
                </Breadcrumbs>

                <CandidateRoleForm 
                    type="edit"
                    defaultValues={{
                        uuid: role?.uuid,
                        startDate: role ? parseDate(role!.startDate).toDate(getLocalTimeZone()) : null,
                        endDate: role && role.endDate ? parseDate(role!.endDate).toDate(getLocalTimeZone()) : null,
                        stillWorkingHere: role && role.endDate === null ? true : false,
                        company: role?.company?.uuid,
                        title: role?.title.uuid,
                        industry: role?.industry.uuid,
                        techSkills: role?.techSkills?.map(y => y.uuid),
                        workLocation: role?.workLocation,
                        whatDidYouBuild: role?.whatDidYouBuild,
                        impactToTheBusiness: role?.impactToTheBusiness,
                        teamSizeAndRole: role?.teamSizeAndRole,
                        techEnvironment: role?.techEnvironment,
                    }}
                />

            </div>
        </div>

    ): null
}