
import React, { useEffect, useState } from "react";
import { BreadcrumbItem, Breadcrumbs, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip, useDisclosure } from "@heroui/react";
import { Navigation } from "../../common/navigation";
import { useNavigate } from "react-router-dom";
import HomeIcon from "../../common/icons/homeIcon";
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks";
import { useKeycloak } from "@react-keycloak/web";
import { getProfile } from "../../../../features/profiles/profilesSlice";
import { canManageCompany, isCompanyUser } from "../../../../lib/company";
import PeopleIcon from "../../common/icons/peopleIcon";
import { deleteHiringTeamMember, getCompany, HiringTeamMember } from "../../../../features/companies/companiesSlice";
import EditIcon from "../../common/icons/editIcon";
import DeleteIcon from "../../common/icons/deleteIcon";
import PlusIcon from "../../common/icons/plusIcon";
import RightArrowIcon from "../../common/icons/rightArrowIcon";

export default function HiringTeam() {

    const { keycloak, initialized } = useKeycloak()
    
    const dispatch = useAppDispatch()
    let navigate = useNavigate()

    const profile = useAppSelector(state => state.profiles.profile)
    const getProfileStatus = useAppSelector(state => state.profiles.getProfileStatus)

    const company = useAppSelector(state => state.companies.company)
    const getCompanyStatus = useAppSelector(state => state.companies.getCompanyStatus)

    const deleteHiringTeamMemberStatus = useAppSelector(state => state.companies.deleteHiringTeamMemberStatus)

    const {isOpen, onOpen, onOpenChange} = useDisclosure()
    const [ selectedUser, setSelectedUser ] = useState('')

  
    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 

    useEffect(() => {

        if(!company || !profile) return
    
        if(!canManageCompany(keycloak, company)) {
            navigate('/')
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company, profile])
      
    
    const getProfiles = () => {

        var profiles = company?.users.slice() || []

        var invited = company?.invitedUsers || []

        for(var i = 0; i < invited.length; i++) {
             profiles.push(invited[i]);
        }

        return profiles
    }

    const cellValue = (item: HiringTeamMember, key: any) => {

        switch(key) {
            case 'name':
                return item.archived !== undefined ? item.firstName + " " + item.lastName : item.emailAddress
            case 'superUser':
                return item.superUser ? 'Yes' : 'No'
            case 'payRates':
                return item.visibility?.find(x => x === 'PAY_RATES') !== undefined ? 'Yes' : 'No'
            case 'location':
                return item.visibility?.find(x => x === 'LOCATION') !== undefined ? 'Yes' : 'No'
            case 'contractDuration':
                return item.visibility?.find(x => x === 'DURATION') !== undefined ? 'Yes' : 'No'
            case 'workLocation':
                return item.visibility?.find(x => x === 'WORK_LOCATION') !== undefined ? 'Yes' : 'No'
            case 'status':
                return item.archived !== undefined ? (!item.archived ? 'Active' : 'Archived') : 'Pending'
            case 'actions': 
                return item.uuid !== keycloak?.tokenParsed?.sub && !item.archived ? (
                    <span className="flex">
                        <Tooltip content="Edit hiring team member" placement="left">
                        <span className="text-lg pr-4 text-default-400 cursor-pointer active:opacity-50" onClick={() => navigate(`/edit-hiring-team-member/${item.uuid}`)}>
                            <EditIcon />
                        </span>
                        </Tooltip>
                        <Tooltip content={`${item.archived === undefined ? 'Delete' : 'Archive'} hiring team member`} placement="right">
                        <span className="text-lg text-danger cursor-pointer active:opacity-50" onClick={() => { setSelectedUser(item.uuid); onOpen(); }}>
                            <DeleteIcon />
                        </span>
                        </Tooltip>
                    </span>
                ) : ''
            default: 
                return ''
        }
    }

    useEffect(() => {
        if (keycloak.authenticated) {

            if(!isCompanyUser(keycloak)) {
                navigate('/')
            }
  
            if(!profile) {
                dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
                .then((response) => {
                  var payload = {token: keycloak.token, uuid: response.payload.uuid}
                  dispatch(getCompany(payload))
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])

    return initialized && keycloak.authenticated && getProfileStatus === 'idle' && getCompanyStatus === 'idle' && company ? (

        <div className="bg-zinc-50 min-h-screen">
            <Navigation />
            
            <div className="ml-5 mr-5 pt-8 sm:container sm:mx-auto">
        
                <div className="flex flex-wrap mb-11">
                    <div className="w-full sm:w-10/12">
                        <Breadcrumbs className="sm:mt-3">
                            <BreadcrumbItem onPress={x => navigate('/')}><HomeIcon />Home</BreadcrumbItem>
                            <BreadcrumbItem onPress={x => navigate('/hiring-team')}><PeopleIcon />Hiring team</BreadcrumbItem>
                            <BreadcrumbItem>View</BreadcrumbItem>
                        </Breadcrumbs>
                    </div>
                    <div className="w-full mt-8 sm:mt-0 sm:w-2/12 flex sm:justify-end">
                        <Button color="success" className="text-white" onClick={() => navigate('/add-hiring-team-member')} endContent={<PlusIcon />}>Add member</Button>
                    </div>
                </div>

                <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                    <ModalContent>
                        {(onClose) => (
                        <>
                        <ModalHeader className="flex flex-col gap-1">{getProfiles().find(x => x.uuid === selectedUser)?.archived === undefined ? 'Delete' : 'Archive'} this member?</ModalHeader>
                        <ModalBody>
                            <p> 
                                {getProfiles().find(x => x.uuid === selectedUser)?.archived === undefined ? 'This will permanently delete the member, and cannot be reversed.' : 'They will no longer be able access your company, this cannot be reversed.'}
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="light" onPress={() => { setSelectedUser(''); onClose() }}>
                                Keep it
                            </Button>
                            <Button 
                                color="danger" 
                                onPress={() => {
                                dispatch(deleteHiringTeamMember({token: keycloak.token, companyId: company?.uuid, uuid: selectedUser}))
                                    .then((response) => {
                                        if(response.meta.requestStatus === 'fulfilled') {
                                            setSelectedUser('');
                                        }
                                        if(response.meta.requestStatus === 'fulfilled' || response.meta.requestStatus === 'rejected') {
                                        onClose()
                                        }
                                    })
                                }
                            }>
                            {getProfiles().find(x => x.uuid === selectedUser)?.archived === undefined ? 'Delete' : 'Archive'} member
                            {(deleteHiringTeamMemberStatus === 'loading') && <Spinner size="sm" color="default" />}  
                            {(deleteHiringTeamMemberStatus !== 'loading') && <RightArrowIcon />}
                            </Button>
                        </ModalFooter>
                        </>
                        )}
                    </ModalContent>
                    </Modal>
                <Table
                    aria-label="Example table with client side sorting"
                    isStriped                    
                    >
                    <TableHeader>
                        <TableColumn key="name">Name</TableColumn>
                        <TableColumn key="superUser">Admin</TableColumn>
                        <TableColumn key="payRates">Pay rates</TableColumn>
                        <TableColumn key="location">Location</TableColumn>
                        <TableColumn key="contractDuration">Contract duration</TableColumn>
                        <TableColumn key="workLocation">Work location</TableColumn>
                        <TableColumn key="status">Status</TableColumn>
                        <TableColumn key="actions">Actions</TableColumn>
                    </TableHeader>
                    <TableBody 
                        items={getProfiles()} 
                        emptyContent={"No members to display."}
                        >
                        {(item) => (
                            <TableRow key={item.uuid}>
                                {(columnKey) => <TableCell>{cellValue(item, columnKey)}</TableCell>}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </div>

    ): null
}