import * as React from "react";
import { useKeycloak } from "@react-keycloak/web"
import "../../../../globals.css"
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks";
import { getProfile } from "../../../../features/profiles/profilesSlice";
import { useEffect } from "react";
import AuthLoading  from "../../common/authLoading";
import { useNavigate, useParams } from "react-router-dom";
import { calculateCandidatePercentageComplete, isValidUrl, normaliseUrl, translateNoticePeriod, translateWorkType } from "../../../../lib/utils";
import CandidatesListLoading from "./loading";
import useSWR from "swr";
import { Accordion, AccordionItem, Card, CardBody, CardHeader, Progress } from "@heroui/react";
import StarIcon from "../../common/icons/starIcon";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import moment from "moment";
import { canManageCandidates } from "../../../../lib/user";
import StarOutlineIcon from "../../common/icons/starOutlineIcon";

export function AdminViewCandidateProfile() {

    const { keycloak, initialized } = useKeycloak()
    let navigate = useNavigate()

    const dispatch = useAppDispatch()
    const profile = useAppSelector(state => state.profiles.profile)

    let { id } = useParams();

    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 

    const fetcher = async function fetcher<JSON = any>(
      input: RequestInfo,
      init?: RequestInit
    ): Promise<JSON> {
      const res = await fetch(input, {
        method: "GET",
        headers: { "Authorization": "Bearer " + keycloak.token, "Content-Type": "application/json" }
      })
      return res.json()
    }
    
    const {data, isLoading} = useSWR<any, string>(`${process.env.REACT_APP_PROFILES_API_URL}profiles/${id}`, fetcher, {
      keepPreviousData: true,
    });

    useEffect(() => {
      if (keycloak.authenticated && !keycloak.userInfo) {

        if(!canManageCandidates(keycloak)) {
          navigate('/')
        }

        if(!profile) {
          dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])

  return initialized && keycloak.authenticated ? (

      <React.Fragment>
        {isLoading && (
          <CandidatesListLoading />
        )}
        {data !== undefined && (

          <div className="flex flex-wrap justify-between">

            <div className="mb-6 lg:mb-0 w-full lg:w-1/3">
              <Card shadow="none">
                <CardHeader className="px-6 pt-4 pb-0 font-semibold justify-between">
                  {data?.tokenDetails?.firstName} {data?.tokenDetails?.lastName}
                </CardHeader>
                <CardBody className="p-6">

                  <span className="bg-slate-100 p-4 mt-4 mb-8 rounded-md">
                    <span className="text-sm justify-between flex">
                      <span>Their profile is {calculateCandidatePercentageComplete(data)}% complete</span>
                      {calculateCandidatePercentageComplete(data) === 100 && <StarIcon fill='gold' />}
                      {calculateCandidatePercentageComplete(data) < 100 && <StarOutlineIcon />}
                    </span>
                    <Progress size="sm" aria-label="Profile completeness" value={calculateCandidatePercentageComplete(data)} className="mt-4"/>
                  </span>

                  <span className="text-slate-500">About them</span>
                  <span className="pb-8">{data?.candidateProfile?.description}</span>

                  <span className="text-slate-500">Email</span>
                  <span className="pb-2">{data?.tokenDetails?.email}</span>

                  <span className="text-slate-500">Phone</span>
                  <span className="pb-2">{formatPhoneNumberIntl(data?.tokenDetails?.phoneNumber)}</span>

                  <span className="text-slate-500">Ideal work location</span>
                  <span className="pb-2">{data?.candidateProfile?.idealWorkLocation?.formatted}</span>

                  <span className="text-slate-500">Date joined</span>
                  <span className="pb-2">{moment(data?.dateCreated).format('Do MMMM, YYYY')}</span>

                  {data?.candidateProfile?.linkedInUrl && ( 
                      <>
                        <span className="text-slate-500">LinkedIn</span>
                        <span className="pb-2">
                          {isValidUrl(data?.candidateProfile?.linkedInUrl) && (<a className="link-primary" href={normaliseUrl(data?.candidateProfile?.linkedInUrl)} target="_blank" rel="noreferrer">{data?.candidateProfile?.linkedInUrl}</a>)}
                          {!isValidUrl(data?.candidateProfile?.linkedInUrl) && (<span>{data?.candidateProfile?.linkedInUrl}</span>)}
                        </span>
                      </>
                  )}
                  {data?.candidateProfile?.gitHubUrl && ( 
                      <>
                        <span className="text-slate-500">GitHub</span>
                        <span className="pb-2">
                          {isValidUrl(data?.candidateProfile?.gitHubUrl) && (<a className="link-primary" href={normaliseUrl(data?.candidateProfile?.gitHubUrl)} target="_blank" rel="noreferrer">{data?.candidateProfile?.gitHubUrl}</a>)}
                          {!isValidUrl(data?.candidateProfile?.gitHubUrl) && (<span>{profile?.candidateProfile?.gitHubUrl}</span>)}
                        </span>
                      </>
                  )}
                </CardBody>
              </Card>
            </div>
            <div className="mb-6 lg:mb-0 lg:pl-10 w-full lg:w-2/3">
              <Card shadow="none" className="mb-10">
                <CardHeader className="bg-slate-100 font-semibold justify-between p-6 py-4">
                  Candidate roles
                </CardHeader>
                <CardBody className="p-6">

                  <Accordion selectionMode="multiple" isCompact={true}>

                    {data.roles?.map((value: any) => (
                      <AccordionItem 
                        key={value.uuid} 
                        textValue={`${value.title.name} - ${value.company.name}`}
                        title={
                          <div className="flex py-2">
                            <span className="w-5/12">
                              {moment(value.startDate).format('MMMM YYYY')}
                              &nbsp;-&nbsp;
                              {value.endDate === null && <span>Current</span>}
                              {value.endDate !== null && <span>{moment(value.endDate).format('MMMM YYYY')}</span>}
                            </span>
                            <span className="w-7/12 font-semibold">
                                {value.title.name}
                                &nbsp;-&nbsp;
                                {value.company.name}
                            </span>
                          </div>
                        }>

                        <div className="text-slate-500">Industry</div>
                        <div className="pb-4">{value.title?.name}</div>
      
                        <div className="text-slate-500">Work location</div>
                        <div className="pb-4">{translateWorkType(value.workLocation)}</div>
      
                        <div className="text-slate-500">What did you build?</div>
                        <div className="pb-4">{value.whatDidYouBuild}</div>
      
                        <div className="text-slate-500">How did this impact the business?</div>
                        <div className="pb-4">{value.impactToTheBusiness}</div>
      
                        <div className="text-slate-500">Team size, and your role with the team?</div>
                        <div className="pb-4">{value.teamSizeAndRole}</div>
      
                        <div className="text-slate-500">What was the tech environment?</div>
                        <div className="pb-8">{value.techEnvironment}</div>
                      </AccordionItem>
                    ))}
                  </Accordion>

                </CardBody>
              </Card>
              <Card shadow="none" className="mb-10">
                <CardHeader className="bg-slate-100 font-semibold justify-between p-6 py-4">
                  Candidate work
                </CardHeader>
                <CardBody className="p-6">

                  <span className="text-slate-500">Current Day Rate (ex Super &amp; GST)</span>
                  {data?.candidateProfile?.currentDayRate !== null && <span className="pb-2">{new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(data?.candidateProfile?.currentDayRate)}</span>}
                  {data?.candidateProfile?.currentDayRate === null && <span className="pb-2">Not provided</span>}

                  <span className="text-slate-500">Notice Period</span>
                  {data?.candidateProfile?.noticePeriod != null && <span className="pb-2">{translateNoticePeriod(data?.candidateProfile?.noticePeriod)}</span>}
                  {data?.candidateProfile?.noticePeriod === null && <span className="pb-2">Not provided</span>}

                  <span className="text-slate-500">Open to work</span>
                  {data?.candidateProfile?.openToWork != null && <span className="pb-2">{data?.candidateProfile?.openToWork ? "Yes" : "No"}</span>}
                  {data?.candidateProfile?.openToWork === null && <span className="pb-2">Not provided</span>}

                  <span className="text-slate-500 mt-8">Ideal Position</span>
                  {data?.candidateProfile?.idealPosition && <span className="pb-2">{data?.candidateProfile?.idealPosition?.name}</span>}
                  {!data?.candidateProfile?.idealPosition && <span className="pb-2">Not provided</span>}

                  <span className="text-slate-500">Ideal Work Type</span>
                  {data?.candidateProfile?.idealWorkType && <span className="pb-2">{translateWorkType(data?.candidateProfile?.idealWorkType)}</span>}
                  {!data?.candidateProfile?.idealWorkType && <span className="pb-2">Not provided</span>}
                  
                  <span className="text-slate-500">Ideal Day Rate (ex Super &amp; GST)</span>
                  {data?.candidateProfile?.idealDayRate !== null && <span className="pb-2">{new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(data?.candidateProfile?.idealDayRate)}</span>}
                  {data?.candidateProfile?.idealDayRate === null && <span className="pb-2">Not provided</span>}

                </CardBody>
              </Card>
            </div>
          </div>
        )}
      </React.Fragment>
    ) : <AuthLoading />
}
