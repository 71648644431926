import * as React from "react";
import { useKeycloak } from "@react-keycloak/web"
import "../../../../globals.css"
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks";
import { deleteProfile, Profile } from "../../../../features/profiles/profilesSlice";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip, useDisclosure } from "@heroui/react";
import moment from 'moment';
import { useState } from "react";
import useSWR from "swr";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import ViewIcon from "../../common/icons/viewIcon";
import { calculateCandidatePercentageComplete } from "../../../../lib/utils";
import DeleteIcon from "../../common/icons/deleteIcon";
import RightArrowIcon from "../../common/icons/rightArrowIcon";

interface Response {
  count: number
  results: Profile[]
}

export function AdminCandidatesListTable() {

    const { keycloak } = useKeycloak()
    let navigate = useNavigate()

    const dispatch = useAppDispatch()
    const profile = useAppSelector(state => state.profiles.profile)
    const deleteProfileStatus = useAppSelector(state => state.profiles.deleteProfileStatus)

    const fetcher = async function fetcher<JSON = any>(
      input: RequestInfo,
      init?: RequestInit
    ): Promise<JSON> {
      const res = await fetch(input, {
        method: "GET",
        headers: { "Authorization": "Bearer " + keycloak.token, "Content-Type": "application/json" }
      })
      return res.json()
    }
    
    const {isOpen, onOpen, onOpenChange} = useDisclosure()
    const [ selectedCandidate, setSelectedCandidate ] = useState('')

    const [page, setPage] = React.useState(1);

    const rowsPerPage = 20;

    const {data, isLoading} = useSWR<Response, string>(`${process.env.REACT_APP_PROFILES_API_URL}profiles?page=${page}&rowsPerPage=${rowsPerPage}`, fetcher, {
      keepPreviousData: true,
    });

    const getKeyValue = (object: any, key: any) => {
      switch(key) {
        case "dateCreated":
          return moment(object[key]).format('Do MMMM, YYYY')
        case "name":
          return object['tokenDetails'] ? object['tokenDetails']['firstName'] + ' ' + object['tokenDetails']['lastName'] : ''
        case "phoneNumber":
          return object['tokenDetails'] && object['tokenDetails']['phoneNumber'] ? formatPhoneNumberIntl(object['tokenDetails']['phoneNumber']) : ''
        case "invited":
          return object[key] === true ? "Yes": "No"
        case "completed":
          return object['completed'] === true ? "Yes" : "No"
        case "detailed":
          return object['candidateProfile'] && object['candidateProfile']['idealPosition'] ? "Yes" : "No"
        case "roleCount":
          return object['roles'] ? object['roles'].length.toString() : "0"
        case "profileCompleteness":
          return calculateCandidatePercentageComplete(object) + '%'
        case "actions":
          return object.uuid !== profile?.uuid ? (
            <span className="flex">
              <Tooltip content="View candidate" placement="left">
                <span className="text-lg pt-1 pr-4 text-default-600 cursor-pointer active:opacity-50" onClick={() => navigate(`/admin-view-candidate/${object['uuid']}`)}>
                  <ViewIcon />
                </span>
              </Tooltip>
              <Tooltip content="Delete candidate" placement="right">
                <span className="text-lg text-danger cursor-pointer active:opacity-50" onClick={() => { setSelectedCandidate(object['uuid']); onOpen(); }}>
                  <DeleteIcon />
                </span>
              </Tooltip>
            </span>
          ) : (
            <span className="flex">
              <Tooltip content="View candidate" placement="left">
                <span className="text-lg pt-1 pr-4 text-default-600 cursor-pointer active:opacity-50" onClick={() => navigate(`/admin-view-candidate/${object['uuid']}`)}>
                  <ViewIcon />
                </span>
              </Tooltip>
            </span>
          )
        default: 
          return object[key];
      }
    }

    const pages = React.useMemo(() => {
      return data?.count ? Math.ceil(data.count / rowsPerPage) : 0;
    }, [data?.count, rowsPerPage]);

    return (
      <>
        <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
          <ModalContent>
            {(onClose) => (
              <>
              <ModalHeader className="flex flex-col gap-1">Delete this candidate?</ModalHeader>
              <ModalBody>
                  <p> 
                      This will permanently delete the candidate and all of their data, and cannot be reversed.
                  </p>
              </ModalBody>
              <ModalFooter>
                <Button variant="light" onPress={() => { setSelectedCandidate(''); onClose() }}>
                  Keep it
                </Button>
                <Button 
                    color="danger" 
                    onPress={() => {
                      dispatch(deleteProfile({token: keycloak.token, profileId: selectedCandidate}))
                        .then((response) => {
                            if(response.meta.requestStatus === 'fulfilled') {
                              setSelectedCandidate('');
                            }
                            if(response.meta.requestStatus === 'fulfilled' || response.meta.requestStatus === 'rejected') {
                              onClose()
                            }
                        })
                    }
                }>
                  Delete candidate
                  {(deleteProfileStatus === 'loading') && <Spinner size="sm" color="default" />}  
                  {(deleteProfileStatus !== 'loading') && <RightArrowIcon />}
                </Button>
              </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
        <Table
          aria-label="Candidates"
          isStriped
    
          bottomContent={
            pages > 0 ? (
              <div className="flex w-full justify-center">
                <Pagination
                  isCompact
                  showControls
                  showShadow
                  color="primary"
                  page={page}
                  total={pages}
                  onChange={(page) => setPage(page)}
                />
              </div>
            ) : null
          }
        >
          <TableHeader>
            <TableColumn key="dateCreated">Date joined</TableColumn>
            <TableColumn key="name">Name</TableColumn>
            <TableColumn key="phoneNumber">Phone number</TableColumn>
            <TableColumn key="invited">Accepted invitation</TableColumn>
            <TableColumn key="completed">Completed basic profile</TableColumn>
            <TableColumn key="detailed">Completed detailed profile</TableColumn>
            <TableColumn key="roleCount"># of roles</TableColumn>
            <TableColumn key="profileCompleteness">Profile completeness</TableColumn>
            <TableColumn key="actions">Actions</TableColumn>

          </TableHeader>
          <TableBody
            items={data?.results ?? []}
            emptyContent={"No candidates to display."}
            isLoading={isLoading}
            loadingContent={<Spinner label="Loading..." />}
          >
            {(item: Profile) => (
              <TableRow key={item?.uuid}>
                {(columnKey) => <TableCell>{getKeyValue(item, columnKey)}</TableCell>}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </>
    );
}
