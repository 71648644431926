
import {Button} from "@heroui/button"
import {Input } from "@heroui/input"
import { Card } from "@heroui/card"
import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/hooks"
import { useKeycloak } from "@react-keycloak/web"
import { getProfile, updateProfile } from "../../../features/profiles/profilesSlice"
import { SubmitHandler, useForm } from "react-hook-form"

import 'react-phone-number-input/style.css'
import { Navigation } from "../common/navigation"
import { Autocomplete, AutocompleteItem, BreadcrumbItem, Breadcrumbs, Select, SelectItem, Spinner } from "@heroui/react"
import HomeIcon from "../common/icons/homeIcon"
import { useNavigate } from "react-router-dom"
import ProfileIcon from "../common/icons/profileIcon"
import AuthLoading from "../common/authLoading"
import MyProfileError from "./error"
import MyProfileLoading from "./loading"
import RightArrowIcon from "../common/icons/rightArrowIcon"
import { getTitles } from "../../../features/candidates/candidatesSlice"
import SelectLoading from "../common/selectLoading"

type FormFields = {
    currentDayRate: string
    noticePeriod: string
    openToWork: string
    idealPosition: any
    idealWorkType: string
    idealDayRate: string
}

export default function EditWorkForm() {

    const { keycloak, initialized } = useKeycloak()
    const dispatch = useAppDispatch()

    const profile = useAppSelector(state => state.profiles.profile)
    const getProfileStatus = useAppSelector(state => state.profiles.getProfileStatus)

    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 

    useEffect(() => {
        if (keycloak.authenticated && !keycloak.userInfo) {

            if(!profile) {
                dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
            }
        }
  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])
      

    return initialized && keycloak.authenticated ? (
        <>
          {getProfileStatus === 'loading' && (
            <MyProfileLoading />
          )}
          {getProfileStatus === 'failed' && (
            <MyProfileError />
          )}
          {getProfileStatus === 'idle' && profile && (
            <EditWorkFormActual />
          )}
        </>
    ) : <AuthLoading />
}

function EditWorkFormActual() {

    const dispatch = useAppDispatch()
    const { keycloak } = useKeycloak()
    let navigate = useNavigate()

    const profile = useAppSelector(state => state.profiles.profile)
    const status = useAppSelector(state => state.profiles.updateProfileStatus)

    const titles = useAppSelector(state => state.candidates.titles)
    const getTitlesStatus = useAppSelector(state => state.candidates.getTitlesStatus)

    const {
        register,
        handleSubmit,
        formState: { errors },        
    } = useForm<FormFields>({
        mode: 'onBlur',
        defaultValues: {
            currentDayRate: profile?.candidateProfile?.currentDayRate ? profile?.candidateProfile?.currentDayRate.toString() : '',
            noticePeriod: profile?.candidateProfile?.noticePeriod,
            openToWork: profile?.candidateProfile?.openToWork ? "true" : "false",
            idealPosition: profile?.candidateProfile?.idealPosition?.uuid,
            idealWorkType: profile?.candidateProfile?.idealWorkType,
            idealDayRate: profile?.candidateProfile?.idealDayRate ? profile?.candidateProfile?.idealDayRate.toString() : '',
        }
    })

    const onSubmit: SubmitHandler<FormFields> = (data: any) => {

        data.currentDayRate = Number(data.currentDayRate);
        data.idealDayRate = Number(data.idealDayRate);
        data.noticePeriod = Number(data.noticePeriod);
        
        var title = titles.find(x => x.uuid === data.idealPosition)
        if(!title) {
            title = titles.find(x => x.name === data.idealPosition)
        }
        data.idealPosition = title ? title : null

        dispatch(updateProfile({token: keycloak.token, uuid: profile?.uuid, payload: data}))
            .then((response) => {
                if(response.meta.requestStatus === 'fulfilled') {
                    navigate('/my-profile')  
                }
            })
    }

    useEffect(() => {
        if (keycloak.authenticated && !keycloak.userInfo) {
            if(titles.length === 0)
                dispatch(getTitles({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
        }
  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])
      
    return (
    
        <div className="bg-zinc-50 min-h-screen">
            <Navigation />
            <div className="ml-5 mr-5 pt-8 sm:container sm:mx-auto">
        
                <Breadcrumbs className="pt-3 mb-11">
                    <BreadcrumbItem onPress={x => navigate('/')}><HomeIcon />Home</BreadcrumbItem>
                    <BreadcrumbItem onPress={x => navigate('/my-profile')}><ProfileIcon />My profile</BreadcrumbItem>
                    <BreadcrumbItem>Edit work details</BreadcrumbItem>
                </Breadcrumbs>

                <Card className="p-8 mx-auto" shadow="sm">
                    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-row flex-wrap w-full justify-between">

                            <div className="w-full lg:w-6/12 lg:pr-20">

                                <div className="w-full mb-6">

                                    <Input
                                        label="Current day rate (ex Super &amp; GST)"
                                        type="number"
                                        placeholder="Enter your current day rate (ex Super &amp; GST)"
                                        isRequired={true}
                                        onClear={() => console.log("clear")}
                                        errorMessage={errors.currentDayRate && "Current day rate is required"}
                                        isInvalid={errors.currentDayRate != null}
                                        {...register("currentDayRate", {required: true})} 
                                        startContent={
                                        <div className="pointer-events-none flex items-center">
                                            <span className="text-default-400 text-small">$</span>
                                        </div>
                                        } />

                                </div>

                                <div className="w-full mb-6">

                                    <Select
                                        label="Notice period"
                                        placeholder="Select your notice period"
                                        errorMessage={errors.noticePeriod && "Notice period is required"}
                                        isRequired={true}
                                        isInvalid={errors.noticePeriod != null}
                                        {...register("noticePeriod", {required: true})}>
                                        
                                        <SelectItem key="0">Immediate</SelectItem>
                                        <SelectItem key="1">1 week</SelectItem>
                                        <SelectItem key="2">2 weeks</SelectItem>
                                        <SelectItem key="3">3 weeks</SelectItem>
                                        <SelectItem key="4">4 weeks</SelectItem>
                                        <SelectItem key="6">6 weeks</SelectItem>
                                        <SelectItem key="8">8 weeks</SelectItem>
                                        <SelectItem key="12">12 weeks</SelectItem>
                                    </Select>

                                </div>

                                <div className="w-full mb-6 lg:mb-12">

                                    <Select
                                        label="Currently open to work?"
                                        placeholder="Select your option"
                                        errorMessage={errors.openToWork && "Open to work is required"}
                                        isRequired={true}
                                        isInvalid={errors.openToWork != null}
                                        {...register("openToWork", {required: true})}>
                                        <SelectItem key="true">Yes</SelectItem>
                                        <SelectItem key="false">No</SelectItem>
                                    </Select>

                                </div>

                            </div> 
                            <div className="w-full pt-12 lg:pt-0 lg:pl-20 lg:w-6/12 flex-column">


                                <div className="w-full mb-6">
                                    {getTitlesStatus === 'loading' && <SelectLoading />}
                                    {getTitlesStatus === 'idle' && (
                                        <Autocomplete
                                            label="Ideal position"
                                            placeholder="Select your ideal position" 
                                            defaultItems={titles}
                                            isRequired={true}
                                            errorMessage="Please select a title"
                                            isInvalid={errors.idealPosition != null}
                                            {...register("idealPosition", {required: true})}>
                                            {(item) => <AutocompleteItem key={item.uuid}>{item.name}</AutocompleteItem>}
                                        </Autocomplete>
                                    )}
                                </div>

                                <div className="w-full mb-6">

                                    <Select
                                        label="Ideal work type"
                                        placeholder="Select from where you want to work"
                                        errorMessage={errors.idealWorkType && "Ideal work type is required"}
                                        isRequired={true}
                                        isInvalid={errors.idealWorkType != null}
                                        {...register("idealWorkType", {required: true})}>
                                        
                                        <SelectItem key="ON_SITE">On-site</SelectItem>
                                        <SelectItem key="REMOTE">Remote</SelectItem>
                                        <SelectItem key="HYBRID">Hybrid</SelectItem>
                                    </Select>
                                </div>
                                <div className="w-full">

                                    <Input
                                        label="Ideal day rate (ex Super &amp; GST)"
                                        type="number"
                                        placeholder="Enter your ideal day rate (ex Super &amp; GST)"
                                        isRequired={true}
                                        onClear={() => console.log("clear")}
                                        errorMessage={errors.idealDayRate && "Ideal day rate is required"}
                                        isInvalid={errors.idealDayRate != null}
                                        {...register("idealDayRate", {required: true})} 
                                        startContent={
                                        <div className="pointer-events-none flex items-center">
                                            <span className="text-default-400 text-small">$</span>
                                        </div>
                                        } />

                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap w-full pt-6">
                            <Button className="bg-transparent font-semibold py-2 px-4 pl-0 mr-6 hover:border-transparent" onPress={() => navigate('/my-profile')}>Cancel</Button>
                            <Button color="primary" type="submit">
                                Save work details
                                {status === 'loading' && <Spinner size="sm" color="default" />} 
                                {status !== 'loading' && <RightArrowIcon />}                                

                            </Button>
                            {status === 'failed' && <span className="form-error block md:inline">Error received from the server, please try again</span>}
                        </div>

                    </form>
                </Card>
            </div>
        </div>
    );
}