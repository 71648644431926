
import React, { useEffect } from "react";
import { BreadcrumbItem, Breadcrumbs } from "@heroui/react";
import { useAppSelector } from "../../../../lib/hooks";
import { Navigation } from "../../common/navigation";
import { useNavigate } from "react-router-dom";
import HomeIcon from "../../common/icons/homeIcon";
import PeopleIcon from "../../common/icons/peopleIcon";
import HiringTeamMemberForm from "./form";
import { canManageCompany } from "../../../../lib/company";
import keycloak from "../../../../keycloak";

export default function AddHiringTeamMemberForm() {

    let navigate = useNavigate()
    
    const profile = useAppSelector(state => state.profiles.profile)
    const company = useAppSelector(state => state.companies.company)

    useEffect(() => {

        if(!company || !profile) return
    
        if(!canManageCompany(keycloak, company)) {
            navigate('/')
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company, profile])
      
    return (

        <div className="bg-zinc-50 min-h-screen">
            <Navigation />
            <div className="ml-5 mr-5 pt-8 sm:container sm:mx-auto">
        
                <Breadcrumbs className="pt-3 mb-12">
                    <BreadcrumbItem onPress={x => navigate('/')}><HomeIcon />Home</BreadcrumbItem>
                    <BreadcrumbItem onPress={x => navigate('/hiring-team')}><PeopleIcon />Hiring team</BreadcrumbItem>
                    <BreadcrumbItem>Add member</BreadcrumbItem>
                </Breadcrumbs>

                <HiringTeamMemberForm 
                    type="add"
                    defaultValues={{
                        emailAddress: '',
                        superUser: false,
                        payRates: true,
                        location: true,
                        duration: true,
                        workLocation: true
                    }}
                />

            </div>
        </div>

    );
}