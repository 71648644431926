import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function AdminDataModeration() {

    let navigate = useNavigate()

    //TODO
    useEffect(() => {
        navigate('/admin-tech-skills')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null

}
