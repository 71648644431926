import Keycloak from 'keycloak-js'
import { Profile } from '../features/profiles/profilesSlice'
import { isCompanyUser } from './company'

export const platformName = () : String => {
    return `${process.env.REACT_APP_PLATFORM_NAME}`
}

export const calculatePercentageComplete = (keycloak: Keycloak, profile: Profile) => {

    if(isCompanyUser(keycloak)) {
      return 100
    }

    return calculateCandidatePercentageComplete(profile)

}

export const calculateCandidatePercentageComplete = (profile: Profile) => {
    if(!profile) return 0

    var score = 30

    if(profile.candidateProfile?.currentDayRate !== null) {
      score += 10
    }

    if(profile.candidateProfile?.currentDayRate !== null) {
      score += 10
    }

    if(profile.candidateProfile?.idealPosition) {
      score += 10
    }

    if(profile.candidateProfile?.idealWorkType) {
      score += 10
    }

    if(profile.candidateProfile?.idealDayRate !== null) {
      score += 10
    }

    if(profile.candidateProfile?.noticePeriod !== null) {
      score += 10
    }

    if(profile.candidateProfile?.linkedInUrl) {
      score +=5
    }

    if(profile.candidateProfile?.gitHubUrl) {
      score +=5
    }

    return score
  }

  export const normaliseUrl = (value: string) => {

    if(!isValidUrl(value)) {
      return value
    
    }
    
    if(value.startsWith('https://') || value.startsWith('http://')) {
      return value
    }

    return 'https://' + value
  }

  export function isValidUrl(value: string) {
    var res = value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g)
    return (res !== null)
  }

  export const translateWorkType = (workType: string) => {
    if(workType === "REMOTE") return "Remote"
    if(workType === "ON_SITE") return "On-site"
    if(workType === "HYBRID") return "Hybrid"
    return null;
  }

  export const translateNoticePeriod = (noticePeriod: string) => {
    if(Number(noticePeriod) === 0) return "Immediate"
    if(Number(noticePeriod) === 1) return "1 week"
    if(Number(noticePeriod) > 1) return `${noticePeriod} weeks`
    return null;
  }

  export const transformPlace = (place: google.maps.places.PlaceResult | null) => {
    if(!place) {
        return null
    }

    return {
        name: place.name,
        formatted: place.formatted_address,
        types: place.types,
        latitude: place.geometry?.location?.lat(),
        longitude: place.geometry?.location?.lng()
    }
}
