import React from 'react';
import { Avatar, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Link, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenu, NavbarMenuItem, NavbarMenuToggle } from "@heroui/react";
import { useKeycloak } from '@react-keycloak/web';
import { useAppSelector } from '../../../lib/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { canManageCandidates, canModerateData, isCandidateUser } from '../../../lib/user';
import N14xLogo from './n14xLogo';
import { canManageCompanies, isCompanyUser } from '../../../lib/company';

export function Navigation() {

    const { keycloak } = useKeycloak()
    const profile = useAppSelector(state => state.profiles.profile)

    let navigate = useNavigate();
    let location = useLocation();

    const { pathname } = location;
    const [isMenuOpen] = React.useState(false);

    const buildMenuItems = () => {

        var menuItems = [{key: '/', label: "Dashboard"}]

        if(isCandidateUser(keycloak)) {
            menuItems.push({key: '/roles', label: "My roles"})
        }

        if(isCompanyUser(keycloak)) {
            menuItems.push({key: '/hiring-team', label: "Hiring team"})
        }

        if(canManageCandidates(keycloak)) {
            menuItems.push({key: '/admin-candidates', label: "Candidates"})
        }

        if(canManageCompanies(keycloak)) {
            menuItems.push({key: '/admin-companies', label: "Companies"})
        }

        if(canModerateData(keycloak)) {
            menuItems.push({key: '/admin-data', label: "Data moderation"})
        }

        menuItems.push({key: '/my-profile', label: "My profile"})
        menuItems.push({key: '/', label: "Logout"})

        return menuItems

    }

    var menuItems = buildMenuItems()


    return (
        <Navbar height="100" maxWidth="full" classNames={{ wrapper: "px-0", }} className="bg-white" isBordered={true}>
            <NavbarContent className="sm:hidden px-4 py-4">
                <NavbarBrand className="justify-start">
                    <div className="h-7" style={{height: '28px', width: '65px'}}>
                        <N14xLogo />
                    </div>
                </NavbarBrand>
                <NavbarMenuToggle 
                    aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                    className="sm:hidden p-4" />
            </NavbarContent>

            <NavbarMenu className="absolute top-0 pt-20">
                {menuItems.map((item, index) => (
                    <NavbarMenuItem key={`${item}-${index}`}>
                        <Link
                            className="w-full"
                            color={
                                index === menuItems.length - 1 ? "danger" : "foreground"
                            }
                            size="md"
                            onPress={x => { 
                                    if(index < menuItems.length - 1) navigate(item.key)
                                    else keycloak.logout() 
                                }}
                            >
                            
                            {item.label}
                        </Link>
                    </NavbarMenuItem>
                ))}
            </NavbarMenu>

            <NavbarContent className="container mx-auto hidden sm:flex justify-between">
                <NavbarBrand className="min-w-24 grow-0">
                    <Link color="foreground" className="cursor-pointer" onPress={x => navigate('/')}>
                        <div className="h-10 my-8" style={{height: '40px', width: '93px'}}>
                            <N14xLogo />
                        </div>
                    </Link>
                </NavbarBrand>
                {profile?.invited && profile?.completed && (
                    <div className="flex justify-between w-full">
                        <div className="flex pt-4">
                            <NavbarItem isActive={pathname === '/'} className="pl-4 pr-6">
                                <Link color="foreground" className="cursor-pointer" onPress={x => navigate('/')}>
                                    Dashboard
                                </Link>
                            </NavbarItem>
                            {isCandidateUser(keycloak) && (
                            <NavbarItem isActive={pathname === '/roles' || pathname.startsWith('/edit-role/') || pathname === '/add-role'}>
                                <Link color="foreground" className="cursor-pointer" onPress={x => navigate('/roles')}>
                                    My roles
                                </Link>
                            </NavbarItem>
                            )}
                            {isCompanyUser(keycloak) && (
                            <NavbarItem className="pl-6" isActive={pathname === '/hiring-team' || pathname.startsWith('/add-hiring-team-member') || pathname.startsWith('/edit-hiring-team-member')}>
                                <Link color="foreground" className="cursor-pointer" onPress={x => navigate('/hiring-team')}>
                                    Hiring team
                                </Link>
                            </NavbarItem>
                            )}

                            {canManageCandidates(keycloak) && (
                            <NavbarItem className="pl-6" isActive={pathname === '/admin-candidates' || pathname.startsWith('/admin-view-candidate/')}>
                                <Link color="foreground" className="cursor-pointer" onPress={x => navigate('/admin-candidates')}>
                                    Candidates
                                </Link>
                            </NavbarItem>
                            )}
                            {canManageCompanies(keycloak) && (
                            <NavbarItem className="pl-6" isActive={pathname === '/admin-companies' || pathname.startsWith('/admin-view-company/')}>
                                <Link color="foreground" className="cursor-pointer" onPress={x => navigate('/admin-companies')}>
                                    Companies
                                </Link>
                            </NavbarItem>
                            )}

                            {canModerateData(keycloak) && (
                            <NavbarItem className="pl-6" isActive={pathname.startsWith('/admin-data') || pathname.startsWith('/admin-tech-skills')}>
                                <Link color="foreground" className="cursor-pointer" onPress={x => navigate('/admin-data')}>
                                    Data moderation
                                </Link>
                            </NavbarItem>
                            )}

                        </div>
                    
                        <Dropdown placement="bottom-end">
                            <DropdownTrigger>
                                <Avatar
                                as="button"
                                className="transition-transform"
                                color="primary"
                                name={`${keycloak.tokenParsed?.given_name?.substring(0, 1)}${keycloak.tokenParsed?.family_name.substring(0, 1)}`}
                                size="lg"
                                // src="https://avatars.githubusercontent.com/u/46544895?s=200&v=4"
                                />
                            </DropdownTrigger>
                            <DropdownMenu aria-label="Profile Actions" variant="flat">
                                <DropdownItem key="profile" textValue="Signed in as {keycloak.tokenParsed?.email}" className="h-14 gap-2 mb-4">
                                    <p className="font-semibold">Signed in as</p>
                                    <p className="">{keycloak.tokenParsed?.email}</p>
                                </DropdownItem>

                                <DropdownItem key="account-manager" textValue="My Account Manager" className="h-14 gap-2 mt-4 mb-10">
                                    <p className="font-semibold">My Account Manager</p>
                                    <p className="mb-0">James Bull</p>
                                    <p>+61 414 136 644</p>
                                    <p className="mb-6">jb@n14.io</p>
                                    <Divider />
                                </DropdownItem>

                                <DropdownItem key="dashboard" textValue="Dashboard" onClick={x => navigate('/')}>Dashboard</DropdownItem>
                                <DropdownItem key="my-profile" textValue="My profile" onClick={x => navigate('/my-profile')}>My profile</DropdownItem>
                                <DropdownItem key="logout" textValue="Logout" color="danger" onClick={() => keycloak.logout()}>
                                    Log out
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                )}
            </NavbarContent>
        </Navbar>
    )
}