
import React, { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useLocation, useNavigate } from 'react-router-dom';
import CandidateRoleForm from "../roles/form";

export default function InitialCandidateRoleForm() {

    const { keycloak } = useKeycloak()

    let navigate = useNavigate();
    let location = useLocation();

    const { pathname } = location;

    useEffect(() => {
        if(pathname !== '/add-initial-role') {
            navigate('/add-initial-role')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])
    
    return (
        <div className="w-full min-h-screen bg-zinc-50 content-center">

            <h1 className="text-center text-2xl font-bold w-full pt-12 xl:pt-0">{keycloak.tokenParsed?.given_name}, tell us more about your current, or most recent role</h1>

            <div className="container mx-auto pt-12 pb-24">
                <div className="w-10/12 sm:w-full max-w-6xl p-8 mx-auto">

                    <CandidateRoleForm 
                        type="initial"
                        defaultValues={{
                            startDate: null,
                            endDate: null,
                            stillWorkingHere: true,
                            company: '',
                            title: '',
                            industry: '',
                            techSkills: '',
                            workLocation: '',
                            whatDidYouBuild: '',
                            impactToTheBusiness: '',
                            teamSizeAndRole: '',
                            techEnvironment: ''
                        }}
                    />
                </div>
            </div>
        </div>
    );
}