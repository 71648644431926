import React from 'react';
import './App.css';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import { Route, Routes } from 'react-router-dom';
import { Dashboard } from './app/pages/dashboard/dashboard';
import { HeroUIProvider } from "@heroui/react";
import { RolesList } from './app/pages/candidates/roles/roles';
import AddCandidateRoleForm from './app/pages/candidates/roles/addRole';
import InitialCandidateRoleForm from './app/pages/candidates/initial/addRole';
import EditCandidateRoleForm from './app/pages/candidates/roles/editRole';
import MyProfile from './app/pages/profile/profile';
import EditProfileForm from './app/pages/profile/editProfile';
import EditWorkForm from './app/pages/profile/editWork';
import { AdminCandidatesList } from './app/pages/admin/candidates/candidates';
import { AdminViewCandidate } from './app/pages/admin/candidates/view';
import { AdminCompaniesList } from './app/pages/admin/companies/companies';
import { AdminViewCompany } from './app/pages/admin/companies/view';
import HiringTeam from './app/pages/companies/hiringTeam/hiringTeam';
import AddHiringTeamMemberForm from './app/pages/companies/hiringTeam/addHiringTeamMember';
import EditHiringTeamMemberForm from './app/pages/companies/hiringTeam/editHiringTeamMember';
import EditCompanyForm from './app/pages/companies/editCompany';
import "primereact/resources/themes/lara-light-blue/theme.css";
import { AdminDataModeration } from './app/pages/admin/data/data';
import { AdminTechSkillsModeration } from './app/pages/admin/data/techSkills';

function App() {

  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <HeroUIProvider>
        <Routes>
          <Route path="/" element={<Dashboard />} />

          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/edit-my-profile" element={<EditProfileForm />} />
          <Route path="/edit-my-work" element={<EditWorkForm />} />

          <Route path="/roles" element={<RolesList />} />
          <Route path="/add-initial-role" element={<InitialCandidateRoleForm />} />
          <Route path="/add-role" element={<AddCandidateRoleForm />} />
          <Route path="/edit-role/:id" element={<EditCandidateRoleForm />} />

          <Route path="/edit-my-company" element={<EditCompanyForm />} />
          <Route path="/hiring-team" element={<HiringTeam />} />
          <Route path="/add-hiring-team-member" element={<AddHiringTeamMemberForm />} />
          <Route path="/edit-hiring-team-member/:id" element={<EditHiringTeamMemberForm />} />

          <Route path="/admin-candidates" element={<AdminCandidatesList />} />
          <Route path="/admin-view-candidate/:id" element={<AdminViewCandidate />} />

          <Route path="/admin-companies" element={<AdminCompaniesList />} />
          <Route path="/admin-view-company/:id" element={<AdminViewCompany />} />

          <Route path="/admin-data" element={<AdminDataModeration />} />
          <Route path="/admin-tech-skills" element={<AdminTechSkillsModeration />} />
        </Routes>
      </HeroUIProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
