import { Button, Card, CardBody, CardFooter, CardHeader, Spinner } from "@heroui/react";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import { useKeycloak } from "@react-keycloak/web";
import { platformName } from "../../../lib/utils";
import { selectUserType } from "../../../features/profiles/profilesSlice";
import { useEffect, useState } from "react";

export default function UserTypeSelection() {

    const dispatch = useAppDispatch()
    const { keycloak } = useKeycloak()

    const [userTypeValue, setUserTypeValue] = useState("");

    const userType = useAppSelector(state => state.profiles.userType)
    const profile = useAppSelector(state => state.profiles.profile)
    const status = useAppSelector(state => state.profiles.selectUserTypeStatus)

    const handleUserTypeSelected = (type: String) => {
        setUserTypeValue(type.toString())
        dispatch(selectUserType({token: keycloak.token!, uuid: profile?.uuid, type: type}))
    }

    const handleCandidateSelected = () => {
        handleUserTypeSelected('candidate')
    }

    const handleCompanySelected = () => {
        handleUserTypeSelected('company')
    }

    useEffect(() => {
        if(status === 'idle' && userType !== null) {
           keycloak.login()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    return (
        
        <div className="w-100 min-h-dvh flex justify-center items-center">
            <div className="text-center">

                <h1 className="text-2xl font-bold w-full pt-8 pb-24">Welcome to {platformName()}, {keycloak.tokenParsed?.given_name}</h1>

                <h2 className="text-lg w-full px-4">In order to provide you a tailored {platformName()} experience, we need to know how you intend to use the platform.<br /><br />Please choose carefully, as this step can't be reversed.</h2>

                <div className="w-100 text-center content-center pt-24 pb-12 lg:flex flex-row justify-center">
                    
                    <div className="lg:max-w-lg content-center pb-12 lg:pb-0 lg:pr-16">
                        <Card className="p-4 pb-8 max-w-md lg:max-w-auto mx-auto">
                            <CardHeader className="flex flex-col">
                                <h1 className="text-xl font-bold pt-8 mb-12 w-full flex justify-center">
                                    <span className="pt-0.5 pr-4 icon-primary">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                            <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span >
                                        Candidate access
                                    </span>
                                </h1>
                            </CardHeader>
                            <CardBody className="px-12">
                                <p className="text-md mb-10 text-center">Looking for the next gig?</p>
                                <p className="text-md text-center leading-8">
                                    Choose this option to create a profile and find your next role.
                                </p>
                            </CardBody>
                            <CardFooter>
                                <div className="text-center pt-12 w-full">
                                    <Button color="primary" type="submit"
                                            disabled={status === 'loading'}
                                            onPress={() => handleCandidateSelected()}>
                                            {status === 'loading' && userTypeValue === 'candidate' &&  <Spinner color="default" size="sm" />}
                                            Continue as a candidate
                                    </Button> 
                                </div>
                            </CardFooter>
                        </Card> 
                        
                    </div>
                    <div className="lg:max-w-lg content-center pb-5 lg:pb-0 lg:pl-16">
                    
                        <Card className="p-4 pb-8 max-w-md lg:max-w-auto mx-auto">
                            <CardHeader className="flex flex-col">
                                <h1 className="text-xl font-bold pt-8 mb-12 w-full flex justify-center">
                                    <span className="pt-0.5 pr-4 icon-primary">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                            <path fillRule="evenodd" d="M3 2.25a.75.75 0 0 0 0 1.5v16.5h-.75a.75.75 0 0 0 0 1.5H15v-18a.75.75 0 0 0 0-1.5H3ZM6.75 19.5v-2.25a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75ZM6 6.75A.75.75 0 0 1 6.75 6h.75a.75.75 0 0 1 0 1.5h-.75A.75.75 0 0 1 6 6.75ZM6.75 9a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75ZM6 12.75a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 0 1.5h-.75a.75.75 0 0 1-.75-.75ZM10.5 6a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75Zm-.75 3.75A.75.75 0 0 1 10.5 9h.75a.75.75 0 0 1 0 1.5h-.75a.75.75 0 0 1-.75-.75ZM10.5 12a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75ZM16.5 6.75v15h5.25a.75.75 0 0 0 0-1.5H21v-12a.75.75 0 0 0 0-1.5h-4.5Zm1.5 4.5a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Zm.75 2.25a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75v-.008a.75.75 0 0 0-.75-.75h-.008ZM18 17.25a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                    <span >
                                        Company access
                                    </span>
                                </h1>
                            </CardHeader>
                            <CardBody className="px-12">
                                <p className="text-md mb-10 text-center">Looking for candidates?</p>
                                <p className="text-md text-center leading-8">
                                    Choose this option to match with great new hires and build your team.
                                </p>
                            </CardBody>
                            <CardFooter>
                                <div className="text-center pt-12 w-full">
                                    <Button color="primary" type="submit"
                                            disabled={status === 'loading'}
                                            onPress={() => handleCompanySelected()}>
                                            {status === 'loading' && userTypeValue === 'company' && <Spinner color="default" size="sm" />}
                                            Continue as a company
                                    </Button> 
                                </div>
                            </CardFooter>
                        </Card> 
                    </div>
                </div>
            </div>
        </div>
    );

}