import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface Company {
  uuid: string
  dateCreated: string
  name: string
  description: string
  logo: string
  actions: any
  approved: boolean
  users: HiringTeamMember[]
  invitedUsers: HiringTeamMember[]
}

export interface HiringTeamMember {
  uuid: string
  firstName: string
  lastName: string
  emailAddress: string
  phoneNumber: string
  superUser: boolean
  visibility: string[]
  archived: boolean
}


export interface CompaniesSliceState {
  company: Company | null
  getCompanyStatus: "idle" | "loading" | "failed"
  updateCompanyStatus: "idle" | "loading" | "failed"
  createCompanyStatus: "idle" | "loading" | "failed"
  approveCompanyStatus: "idle" | "loading" | "failed"
  addHiringTeamMemberStatus: "idle" | "loading" | "failed"
  editHiringTeamMemberStatus: "idle" | "loading" | "failed"
  deleteHiringTeamMemberStatus: "idle" | "loading" | "failed"
}

const initialState: CompaniesSliceState = {
  company: null,
  getCompanyStatus: 'idle',
  updateCompanyStatus: 'idle',
  createCompanyStatus: 'idle',
  approveCompanyStatus: 'idle',
  addHiringTeamMemberStatus: 'idle',
  editHiringTeamMemberStatus: 'idle',
  deleteHiringTeamMemberStatus: 'idle'
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    updateActions(state, action) {

      if(!state.company) {
        return
      }

      var company = {...state.company!}

      state.company = {
        ...company,
        actions: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompany.pending, (state) => {
        state.getCompanyStatus = 'loading'
        state.company = null
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.getCompanyStatus = 'idle'
        state.company = action.payload
      })
      .addCase(getCompany.rejected, (state) => {
        state.getCompanyStatus = 'failed' 
        state.company = null
      })
      .addCase(createCompany.pending, (state) => {
        state.createCompanyStatus = 'loading'
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.createCompanyStatus = 'idle'
        state.company = action.payload
      })
      .addCase(createCompany.rejected, (state) => {
        state.createCompanyStatus = 'failed' 
      })
      .addCase(updateCompany.pending, (state) => {
        state.updateCompanyStatus = 'loading'
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.updateCompanyStatus = 'idle'
        state.company = action.payload
      })
      .addCase(updateCompany.rejected, (state) => {
        state.updateCompanyStatus = 'failed' 
      })
      .addCase(updateCompanyActions.pending, (state) => {
        state.updateCompanyStatus = 'loading'
      })
      .addCase(updateCompanyActions.fulfilled, (state, action) => {
        state.updateCompanyStatus = 'idle'
      })
      .addCase(updateCompanyActions.rejected, (state) => {
        state.updateCompanyStatus = 'failed' 
      })
      .addCase(approveCompany.pending, (state) => {
        state.approveCompanyStatus = 'loading'
      })
      .addCase(approveCompany.fulfilled, (state, action) => {
        state.approveCompanyStatus = 'idle'
      })
      .addCase(approveCompany.rejected, (state) => {
        state.approveCompanyStatus = 'failed' 
      })
      .addCase(addHiringTeamMember.pending, (state) => {
        state.addHiringTeamMemberStatus = 'loading'
      })
      .addCase(addHiringTeamMember.fulfilled, (state, action) => {
        state.addHiringTeamMemberStatus = 'idle'

        var company = {...state.company!}
        var invitedUsers = company.invitedUsers
        invitedUsers.push(action.payload)

        state.company = {
          ...company,
          invitedUsers: invitedUsers
        }
      })
      .addCase(addHiringTeamMember.rejected, (state) => {
        state.addHiringTeamMemberStatus = 'failed' 
      })
      .addCase(editHiringTeamMember.pending, (state) => {
        state.editHiringTeamMemberStatus = 'loading'
      })
      .addCase(editHiringTeamMember.fulfilled, (state, action) => {
        state.editHiringTeamMemberStatus = 'idle'

        var company = {...state.company!}

        state.company = {
          ...company,
          users: action.payload.users,
          invitedUsers: action.payload.invitedUsers
        }
      })
      .addCase(editHiringTeamMember.rejected, (state) => {
        state.editHiringTeamMemberStatus = 'failed' 
      })
      .addCase(deleteHiringTeamMember.pending, (state) => {
        state.deleteHiringTeamMemberStatus = 'loading'
      })
      .addCase(deleteHiringTeamMember.fulfilled, (state, action) => {
        state.deleteHiringTeamMemberStatus = 'idle'

        state.company = action.payload
      })
      .addCase(deleteHiringTeamMember.rejected, (state) => {
        state.deleteHiringTeamMemberStatus = 'failed' 
      });
  },
})

export const getCompany = createAsyncThunk(
  'companies/getCompany',
  async (data: any, thunkAPI) => {
    const response = await fetch(`${process.env.REACT_APP_COMPANIES_API_URL}companies/by-user`, {
      method: "GET",
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const createCompany = createAsyncThunk(
  'companies/createCompany',
  async (data: any, thunkAPI) => {
      const response = await fetch(`${process.env.REACT_APP_COMPANIES_API_URL}companies`, {
      method: "POST",
      body: JSON.stringify(data.payload),
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const updateCompany = createAsyncThunk(
  'companies/updateCompany',
  async (data: any, thunkAPI) => {
      const response = await fetch(`${process.env.REACT_APP_COMPANIES_API_URL}companies/${data.payload.uuid}`, {
      method: "PUT",
      body: JSON.stringify(data.payload),
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const updateCompanyActions = createAsyncThunk(
  'companies/updateCompanyActions',
  async (data: any, thunkAPI) => {
      const response = await fetch(`${process.env.REACT_APP_COMPANIES_API_URL}companies/${data.payload.uuid}/actions`, {
      method: "PUT",
      body: JSON.stringify(data.payload.actions),
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const approveCompany = createAsyncThunk(
  'companies/approveCompany',
  async (data: any, thunkAPI) => {
      const response = await fetch(`${process.env.REACT_APP_COMPANIES_API_URL}companies/${data.uuid}/approve`, {
      method: "PUT",
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)


export const addHiringTeamMember = createAsyncThunk(
  'companies/addHiringTeamMember',
  async (data: any, thunkAPI) => {

      const response = await fetch(`${process.env.REACT_APP_COMPANIES_API_URL}companies/${data.uuid}/hiring-team`, {
      method: "POST",
      body: JSON.stringify(data.payload),
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const editHiringTeamMember = createAsyncThunk(
  'companies/editHiringTeamMember',
  async (data: any, thunkAPI) => {
      const response = await fetch(`${process.env.REACT_APP_COMPANIES_API_URL}companies/${data.companyId}/hiring-team/${data.uuid}`, {
      method: "PUT",
      body: JSON.stringify(data.payload),
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export const deleteHiringTeamMember = createAsyncThunk(
  'companies/deleteHiringTeamMember',
  async (data: any, thunkAPI) => {

    const response = await fetch(`${process.env.REACT_APP_COMPANIES_API_URL}companies/${data.companyId}/hiring-team/${data.uuid}`, {
      method: "DELETE",
      headers: { "Authorization": "Bearer " + data.token, "Content-Type": "application/json" }
    });

    if(response.ok)
      return await response.json()

    return thunkAPI.rejectWithValue(await response.json())
  }
)

export default companiesSlice.reducer;
export const { updateActions } = companiesSlice.actions
