import * as React from "react";
import { useKeycloak } from "@react-keycloak/web"
import "../../../globals.css"
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import { platformName } from "../../../lib/utils";
import { getProfile, updateProfileCompleted, updateProfileInvited } from "../../../features/profiles/profilesSlice";
import { useEffect, useState } from "react";
import { Navigation } from "../common/navigation";
import DashboardLoading  from "./loading";
import { BreadcrumbItem, Breadcrumbs, Card, CardBody, CardHeader } from "@heroui/react";
import { useNavigate } from "react-router-dom";
import HomeIcon from "../common/icons/homeIcon";
import EditIcon from "../common/icons/editIcon";
import { invitationCodeRequired  } from "../../../lib/user";
import InitialCompanyForm from "../companies/initial/createCompany";
import { getCompany, updateCompanyActions } from "../../../features/companies/companiesSlice";
import SuperUserIcon from "../common/icons/superUserIcon";
import MoneyIcon from "../common/icons/moneyIcon";
import MapPinIcon from "../common/icons/mapPinIcon";
import DurationIcon from "../common/icons/durationIcon";
import WorkLocationIcon from "../common/icons/workLocationIcon";
import { canManageCompany } from "../../../lib/company";
import { internationalisePhoneNumberForDisplay } from "../../../lib/phoneNumber";
import moment from "moment";
import CloseBannerIcon from "../common/icons/closeBannerIcon";

export function CompanyDashboard() {

    const { keycloak, initialized } = useKeycloak()

    const dispatch = useAppDispatch()
    let navigate = useNavigate()

    const profile = useAppSelector(state => state.profiles.profile)
    const getProfileStatus = useAppSelector(state => state.profiles.getProfileStatus)

    const company = useAppSelector(state => state.companies.company)
    const getCompanyStatus = useAppSelector(state => state.companies.getCompanyStatus)

    const geolocation = useAppSelector(state => state.profiles.geolocation)

    const [ dismissed, setDismissed ] = useState(false)

    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 

    useEffect(() => {
      if (keycloak.authenticated && !keycloak.userInfo) {

        if(!profile) {
          dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
            .then((response) => {
              var payload = {token: keycloak.token, uuid: response.payload.uuid}
              dispatch(getCompany(payload))
            })
        
          return  
        }

        if(!company) {
          var payload = {token: keycloak.token, uuid: profile.uuid};
          dispatch(getCompany(payload))
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])

    useEffect(() => {

      if(!profile || profile.invited || profile.completed) return
      if(!company || !company.users) return

      if(company.users.filter(x => x.uuid === profile.userId).length === 1) {
        if(!profile.completed) {
          dispatch(updateProfileCompleted({completed: true}))
        }
        if(!profile.invited) {
          dispatch(updateProfileInvited({invited: true}))
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company])

    if(getCompanyStatus === 'loading') return <DashboardLoading />

    return (

      <React.Fragment>
        {getProfileStatus === 'idle' && profile && !company &&  (
          <InitialCompanyForm />
        )}
        {getProfileStatus === 'idle' && profile && company && (!company.approved || (!profile?.invited && invitationCodeRequired(keycloak))) && ( 
          <div className="w-100 min-h-dvh flex text-center justify-center items-center">
            <h1 className="text-xl font-bold mb-12 w-full">
              Thank you for choosing {platformName()}. We are working on getting your account verified
            </h1>
          </div>
        )}
        {getProfileStatus === 'idle' && profile && company && company.approved && (profile?.invited || !invitationCodeRequired(keycloak)) && (
          <div className="bg-zinc-50 min-h-screen">
            <Navigation />
            <div className="ml-5 mr-5 pt-8 sm:container sm:mx-auto">

              <Breadcrumbs className="mt-3 mb-11">
                <BreadcrumbItem onPress={x => navigate('/')}><HomeIcon />Home</BreadcrumbItem>
                <BreadcrumbItem>Dashboard</BreadcrumbItem>
              </Breadcrumbs>

              {company?.actions?.addHiringTeam !== undefined && company?.actions?.addHiringTeam === true && !dismissed && (
                <div className="bg-yellow-50 border-l-4 border-yellow-500 text-yellow-800 p-4 rounded-md mb-12 flex justify-between" role="alert">
                  <div className="flex items-center">
                    <div className="pr-4">
                      <svg className="fill-current h-6 w-6 text-yellow-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>
                    </div>
                    <div>
                      <p className="font-bold pb-2">Hiring team</p>
                      <p>You only have one member on your hiring team. <a className="font-bold" href="/add-hiring-team-member" onClick={(e) => { e.preventDefault(); navigate('/add-hiring-team-member')}}>Manage your hiring team here</a></p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="cursor-pointer" onClick={() => {
                        setDismissed(true)
                        var c = structuredClone(company)
                        if(!c.actions) c.actions = {}
                        c.actions['addHiringTeam'] = false

                        dispatch(updateCompanyActions({token: keycloak.token, payload: c}))
                      }}>
                      <CloseBannerIcon />
                    </div>
                  </div>
                </div>
              )}

              <div className="flex flex-wrap justify-between">
          
                <div className="mb-6 lg:mb-0 w-full lg:w-1/3">

                  <Card shadow="none">
                    <CardHeader className="px-6 pt-4 pb-0 font-semibold justify-between">
                      {keycloak.tokenParsed?.given_name} {keycloak.tokenParsed?.family_name}

                      {canManageCompany(keycloak, company) && (
                        <span className="text-lg text-default-900 cursor-pointer active:opacity-50" onClick={() => navigate('/edit-my-company')}>
                          <EditIcon />
                        </span>
                      )}
                    </CardHeader>
                    <CardBody className="p-6">

                      <span className="bg-slate-100 p-4 mt-4 mb-8 rounded-md">
                        <span className="text-sm justify-between flex">
                          <span>
                            <span className="text-slate-500">Company details</span>
                            <span className="block">{company?.name}</span>
                          </span>
                          {company?.logo && (
                            <div>
                              <img className="max-w-24" src={`${process.env.REACT_APP_IMAGE_PATH}${company.logo}`} alt={`${company?.name} logo`} />
                            </div>
                          )}
                        </span>
                      </span>

                      <span className="text-slate-500">Email</span>
                      <span className="pb-2">{keycloak.tokenParsed?.email}</span>

                      <span className="text-slate-500">Phone</span>
                      <span className="pb-2">{internationalisePhoneNumberForDisplay(keycloak.tokenParsed?.phone_number, geolocation)}</span>

                      <span className="text-slate-500">Date joined</span>
                      <span className="pb-2">{moment(profile?.dateCreated).format('Do MMMM, YYYY')}</span>


                    </CardBody>
                  </Card>

                </div>
                <div className="mb-6 lg:mb-0 lg:pl-10 w-full lg:w-2/3">
                  <Card shadow="none" className="mb-10">
                    <CardHeader className="bg-slate-100 font-semibold justify-between p-6 py-4">
                      Hiring team
                      {canManageCompany(keycloak, company) && (
                        <span className="text-lg text-default-900 cursor-pointer active:opacity-50" onClick={() => navigate('/hiring-team')}>
                          <EditIcon />
                        </span>
                      )}
                    </CardHeader>
                    <CardBody className="p-6">
                      <div className="flex flex-row flex-wrap">
                        {company?.users?.filter(x => !x.archived).map((item, index) => (
                          <span key={index} className="w-1/3 pr-4 min-h-40">
                              <strong className="pb-2 flex"><span className="pr-2">{item.firstName} {item.lastName}</span>{item.superUser === true && <span><SuperUserIcon /></span>}</strong>
                              {item.visibility.map((vis, index2) => (
                                <span key={index2}>
                                  {vis === 'PAY_RATES' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><MoneyIcon /></span><span className="pl-2 pt-0.5">Pay rates</span></em>}
                                  {vis === 'LOCATION' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><MapPinIcon /></span><span className="pl-2 pt-0.5">Location</span></em>}
                                  {vis === 'DURATION' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><DurationIcon /></span><span className="pl-2 pt-0.5">Contract duration</span></em>}
                                  {vis === 'WORK_LOCATION' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><WorkLocationIcon /></span><span className="pl-2 pt-0.5">Work location</span></em>}
                                </span>
                              ))}
                          </span>
                        ))}
                        </div>
                    </CardBody>
                  </Card>
                  <Card shadow="none" className="mb-10">
                    <CardHeader className="bg-slate-100 font-semibold justify-between p-6 py-4">
                      Pending hiring team members
                    </CardHeader>
                    <CardBody className="p-6">
                        {company?.invitedUsers && company?.invitedUsers.length > 0 && (
                          <div className="block">
                            {company?.invitedUsers.map((profile, index) => (
                                <span key={index} className="block w-1/3 pr-4 min-h-40">
                                  <strong className="pb-2 flex"><span className="pr-2">{profile.emailAddress}</span>{profile.superUser === true && <span><SuperUserIcon /></span>}</strong>
                                  {profile.visibility.map((vis, index2) => (
                                    <span key={index2} className="block">
                                      {vis === 'PAY_RATES' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><MoneyIcon /></span><span className="pl-2 pt-0.5">Pay rates</span></em>}
                                      {vis === 'LOCATION' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><MapPinIcon /></span><span className="pl-2 pt-0.5">Location</span></em>}
                                      {vis === 'DURATION' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><DurationIcon /></span><span className="pl-2 pt-0.5">Contract duration</span></em>}
                                      {vis === 'WORK_LOCATION' && <em className="pb-1 text-sm block flex flex-row"><span className="text-primary"><WorkLocationIcon /></span><span className="pl-2 pt-0.5">Work location</span></em>}
                                    </span>
                                  ))}
                                </span>
                            ))}
                          </div>
                        )}
                        {company?.invitedUsers && company?.invitedUsers.length === 0 && (
                          <div>
                            No pending invites
                          </div>
                        )}

                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
}
