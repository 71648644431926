import * as React from "react";
import { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web"
import "../../../globals.css"
import AuthLoading  from "../common/authLoading";
import { isCandidateUser } from "../../../lib/user";
import { CandidateDashboard } from "./candidateDashboard";
import { CompanyDashboard } from "./companyDashboard";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import { getProfile } from "../../../features/profiles/profilesSlice";
import DashboardLoading from "./loading";
import DashboardError from "./error";
import UserTypeSelection from "./userTypeSelection";
import { isCompanyUser } from "../../../lib/company";
import { getRoles } from "../../../features/candidates/candidatesSlice";

export function Dashboard() {

    const dispatch = useAppDispatch()

    const { keycloak, initialized } = useKeycloak()

    const profile = useAppSelector(state => state.profiles.profile)
    const getProfileStatus = useAppSelector(state => state.profiles.getProfileStatus)
    const getRolesStatus = useAppSelector(state => state.candidates.getRolesStatus)

    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
      keycloak?.login()
    } 

    useEffect(() => {
      if (keycloak.authenticated && !keycloak.userInfo) {

        if(!profile) {
          dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
            .then((response) => {
              var payload = {token: keycloak.token, uuid: response.payload.uuid}
              dispatch(getRoles(payload))
            })
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])

    if(!initialized || !keycloak.authenticated) return <AuthLoading />

    if(getProfileStatus === 'loading' || getRolesStatus === 'loading') return <DashboardLoading />
    if(getProfileStatus === 'failed') return <DashboardError />
    
    if(!profile) return null
  
    return isCandidateUser(keycloak) ? <CandidateDashboard /> : isCompanyUser(keycloak) ? <CompanyDashboard /> : <UserTypeSelection />
}
