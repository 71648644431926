import * as React from "react";
import { useKeycloak } from "@react-keycloak/web"
import "../../../../globals.css"
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks";
import { deleteProfile, Profile } from "../../../../features/profiles/profilesSlice";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip, useDisclosure } from "@heroui/react";
import moment from 'moment';
import { useState } from "react";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../common/icons/deleteIcon";
import RightArrowIcon from "../../common/icons/rightArrowIcon";
import ApproveIcon from "../../common/icons/approveIcon";
import { approveTechSkill, deleteTechSkill } from "../../../../features/candidates/candidatesSlice";

interface Response {
  count: number
  results: Profile[]
}

export function AdminTechSkillsModerationTable() {

    const { keycloak } = useKeycloak()

    const dispatch = useAppDispatch()
    const deleteTechSkillStatus = useAppSelector(state => state.candidates.deleteTechSkillStatus)
    const approveTechSkillStatus = useAppSelector(state => state.candidates.approveTechSkillStatus)

    const fetcher = async function fetcher<JSON = any>(
      input: RequestInfo,
      init?: RequestInit
    ): Promise<JSON> {
      const res = await fetch(input, {
        method: "GET",
        headers: { "Authorization": "Bearer " + keycloak.token, "Content-Type": "application/json" }
      })
      return res.json()
    }
    
    const approveModal = useDisclosure()
    const deleteModal = useDisclosure()

    const [ selectedTechSkill, setSelectedTechSkill ] = useState('')

    const [page, setPage] = React.useState(1);

    const rowsPerPage = 20;

    const {data, isLoading} = useSWR<Response, string>(`${process.env.REACT_APP_CANDIDATES_API_URL}candidates/tech-skills/custom?page=${page}&rowsPerPage=${rowsPerPage}`, fetcher, {
      keepPreviousData: true,
    });

    const getKeyValue = (object: any, key: any) => {
      switch(key) {
        case "customDateCreated":
          return moment(object[key]).format('Do MMMM, YYYY')
        case "name":
          return object[key]
        case "actions":
          return (
            <span className="flex">
              <Tooltip content="Approve tech skill" placement="left">
                <span className="text-lg text-success pt-0.5 pr-4 text-default-600 cursor-pointer active:opacity-50" onClick={() => { setSelectedTechSkill(object['uuid']); approveModal.onOpen(); }}>
                  <ApproveIcon />
                </span>
              </Tooltip>
              <Tooltip content="Delete tech skill" placement="right">
                <span className="text-lg text-danger cursor-pointer active:opacity-50" onClick={() => { setSelectedTechSkill(object['uuid']); deleteModal.onOpen(); }}>
                  <DeleteIcon />
                </span>
              </Tooltip>
            </span>
          )
        default: 
          return object[key];
      }
    }

    const pages = React.useMemo(() => {
      return data?.count ? Math.ceil(data.count / rowsPerPage) : 0;
    }, [data?.count, rowsPerPage]);

    return (
      <>
        <Modal isOpen={deleteModal.isOpen} onOpenChange={deleteModal.onOpenChange}>
          <ModalContent>
            {(onClose) => (
              <>
              <ModalHeader className="flex flex-col gap-1">Delete this tech skill?</ModalHeader>
              <ModalBody>
                  <p> 
                      This will permanently delete the tech skill and remove it from all candidate roles where it exists, and cannot be reversed.
                  </p>
              </ModalBody>
              <ModalFooter>
                <Button variant="light" onPress={() => { setSelectedTechSkill(''); onClose() }}>
                  Keep it
                </Button>
                <Button 
                    color="danger" 
                    onPress={() => {
                      dispatch(deleteTechSkill({token: keycloak.token, uuid: selectedTechSkill}))
                        .then((response) => {
                            if(response.meta.requestStatus === 'fulfilled') {
                              setSelectedTechSkill('');
                            }
                            if(response.meta.requestStatus === 'fulfilled' || response.meta.requestStatus === 'rejected') {
                              deleteModal.onClose()
                            }
                        })
                    }
                }>
                  Delete tech skill
                  {(deleteTechSkillStatus === 'loading') && <Spinner size="sm" color="default" />}  
                  {(deleteTechSkillStatus !== 'loading') && <RightArrowIcon />}
                </Button>
              </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
        <Modal isOpen={approveModal.isOpen} onOpenChange={approveModal.onOpenChange}>
          <ModalContent>
            {(onClose) => (
              <>
              <ModalHeader className="flex flex-col gap-1">Approve this tech skill?</ModalHeader>
              <ModalBody>
                  <p> 
                      This will approve the tech skill and allow any candidate to search for and use it, and cannot be reversed.
                  </p>
              </ModalBody>
              <ModalFooter>
                <Button variant="light" onPress={() => { setSelectedTechSkill(''); onClose() }}>
                  Cancel
                </Button>
                <Button 
                    color="success" 
                    onPress={() => {
                      dispatch(approveTechSkill({token: keycloak.token, uuid: selectedTechSkill}))
                        .then((response) => {
                            if(response.meta.requestStatus === 'fulfilled') {
                              setSelectedTechSkill('');
                            }
                            if(response.meta.requestStatus === 'fulfilled' || response.meta.requestStatus === 'rejected') {
                              approveModal.onClose()
                            }
                        })
                    }
                }>
                  Approve tech skill
                  {(approveTechSkillStatus === 'loading') && <Spinner size="sm" color="default" />}  
                  {(approveTechSkillStatus !== 'loading') && <RightArrowIcon />}
                </Button>
              </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
        <Table
          aria-label="Candidates"
          isStriped
    
          bottomContent={
            pages > 0 ? (
              <div className="flex w-full justify-center">
                <Pagination
                  isCompact
                  showControls
                  showShadow
                  color="primary"
                  page={page}
                  total={pages}
                  onChange={(page) => setPage(page)}
                />
              </div>
            ) : null
          }
        >
          <TableHeader>
            <TableColumn key="customDateCreated">Date created</TableColumn>
            <TableColumn key="name">Tech skill</TableColumn>
            <TableColumn key="actions">Actions</TableColumn>
          </TableHeader>
          <TableBody
            items={data?.results ?? []}
            emptyContent={"No custom tech skills to display."}
            isLoading={isLoading}
            loadingContent={<Spinner label="Loading..." />}
          >
            {(item: Profile) => (
              <TableRow key={item?.uuid}>
                {(columnKey) => <TableCell>{getKeyValue(item, columnKey)}</TableCell>}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </>
    );
}
