
import {Button} from "@heroui/button";
import { Textarea} from "@heroui/input";
import { Select, SelectItem} from "@heroui/select";
import { now, today, getLocalTimeZone} from "@internationalized/date";
import { Autocomplete, AutocompleteItem } from "@heroui/autocomplete";
import { Card } from "@heroui/card";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks";
import { useKeycloak } from "@react-keycloak/web";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { addRole, editRole, getCompanies, getIndustries, getRoles, getTechSkills, getTitles, TechSkill, updateTechSkills } from "../../../../features/candidates/candidatesSlice";
import { Checkbox, Chip, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner, useDisclosure } from "@heroui/react";
import SelectLoading from "../../common/selectLoading";
import { useNavigate } from "react-router-dom";
import { getProfile, updateProfileRoleAdded } from "../../../../features/profiles/profilesSlice";
import ChipPlusIcon from "../../common/icons/chipPlusIcon";
import { SearchIcon } from "../../common/icons/searchIcon";
import RightArrowIcon from "../../common/icons/rightArrowIcon";
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import { platformName } from "../../../../lib/utils";
import PlusIcon from "../../common/icons/plusIcon";
import {v4 as uuidv4} from 'uuid';


type FormFields = {
    startDate: Date | null
    endDate: Date | null
    stillWorkingHere: boolean
    company: string
    title: string
    industry: string
    techSkills: string
    workLocation: string
    whatDidYouBuild: string
    impactToTheBusiness: string
    teamSizeAndRole: string
    techEnvironment: string
}

export default function CandidateRoleForm(props: any) {

    const dispatch = useAppDispatch()
    let navigate = useNavigate()

    const { keycloak, initialized } = useKeycloak()

    const profile = useAppSelector(state => state.profiles.profile)

    const roles = useAppSelector(state => state.candidates.roles)
    const editRoleStatus = useAppSelector(state => state.candidates.editRoleStatus)
    const addRoleStatus = useAppSelector(state => state.candidates.addRoleStatus)

    const companies = useAppSelector(state => state.candidates.companies)
    const getCompaniesStatus = useAppSelector(state => state.candidates.getCompaniesStatus)

    const titles = useAppSelector(state => state.candidates.titles)
    const getTitlesStatus = useAppSelector(state => state.candidates.getTitlesStatus)

    const industries = useAppSelector(state => state.candidates.industries)
    const getIndustriesStatus = useAppSelector(state => state.candidates.getIndustriesStatus)

    const techSkills = useAppSelector(state => state.candidates.techSkills)
    const getTechSkillsStatus = useAppSelector(state => state.candidates.getTechSkillsStatus)

    const [ selectedTechSkills, setSelectedTechSkills ] = useState<TechSkill[]>([])
    const [ selectedTechSkillsTouched, setSelectedTechSkillsTouched ] = useState(false)

    const [ customTechSkill, setCustomTechSkill ] = useState<string>('')

    const [ customCompany, setCustomCompany ] = useState('')
    
    const decisionModal = useDisclosure()
    const techSkillsModal = useDisclosure()

    const {
        control,
        handleSubmit,
        getValues,
        watch,
        reset,
        formState: { errors },        
    } = useForm<FormFields>({
        mode: 'onBlur',
        defaultValues: props.defaultValues
    })

    const stillWorkingHere = watch('stillWorkingHere')

    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 
    
    useEffect(() => {
        if (keycloak.authenticated) {

            dispatch(getCompanies({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))

            if(titles.length === 0)
                dispatch(getTitles({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))

            if(industries.length === 0)
                dispatch(getIndustries({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
    
            if(!profile) {
                dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
                    .then((response) => {
                        if(response.meta.requestStatus === 'fulfilled') {
                            dispatch(getTechSkills({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!, profileId: response.payload.uuid}))
                            dispatch(getRoles({token: keycloak.token, uuid: response.payload.uuid}))
                        }
                })
            } else {
                if(techSkills.length === 0) {
                    dispatch(getTechSkills({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!, profileId: profile?.uuid}))
                }
    
                if(!roles || roles.length === 0) {
                    dispatch(getRoles({token: keycloak.token, uuid: profile.uuid}))
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])

    useEffect(() => {
        if(techSkills.length > 0 ) {
            populateTechSkills(props.defaultValues.techSkills)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [techSkills])

    const populateTechSkills = (selectedSkills: any) => {
        if(!selectedSkills) {
            return
        }
        setSelectedTechSkills(selectedSkills?.map((x: any) => techSkills.find((y: any) => y.uuid === x)))
    }

    const deselectTechSkill = (uuid: string) => {

        if(!selectedTechSkills) {
            return
        }

        const index = selectedTechSkills.findIndex(x => x['uuid'] === uuid)
        if (index > -1) {
            var selected = selectedTechSkills.slice()            
            selected.splice(index, 1);
            setSelectedTechSkills(selected)
            setSelectedTechSkillsTouched(true)
        }

    }

    const addCustomTechSkill = async (value : string) => {

        if(!value) return;
        var name = value?.trim();

        if(!name) return;

        name = String(name).charAt(0).toUpperCase() + String(name).slice(1);

        var skill = { uuid: uuidv4(), name: name, custom: true}

        var skills = techSkills.slice()            
        skills.push(skill)

        await dispatch(updateTechSkills(skills))

        selectTechSkillWithSkills(skills, skill.uuid)

        setCustomTechSkill('')
    }

    const selectTechSkillWithSkills = (skills: TechSkill[], uuid: string) => {

        if(!selectedTechSkills) {
            return
        }

        const index = selectedTechSkills.findIndex(x => x['uuid'] === uuid)
        
        if (index === -1) {
            const found = skills.find(x => x.uuid === uuid)
        
            if(found) {
                var selected = selectedTechSkills.slice()      
                selected.push(found)
                setSelectedTechSkills(selected)
            }
        }

    }

    const selectTechSkill = (uuid: string) => {
        selectTechSkillWithSkills(techSkills, uuid)
    }

    const techSkillsValid = () : boolean => {
        return selectedTechSkills.length > 0
    }

    const techSkillsError = () : string => {
        return !techSkillsValid() ? 'You must select at least one skill' : ''
    }

    const processData = (data: any) : any => {

        data.techSkills = selectedTechSkills.filter(x => x.custom !== true).map(x => x.uuid)
        data.customTechSkills = selectedTechSkills.filter(x => x.custom === true)

        var company = companies.find(x => x.name === data.company)
        if(!company) {
            company = companies.find(x => x.uuid === data.company)
        }
        if(company) {
            data.company = company.uuid
        } else if(customCompany) {
            data.customCompany = customCompany
            data.company = null
        }

        var title = titles.find(x => x.uuid === data.title)
        if(!title) {
            title = titles.find(x => x.name === data.title)
        }
        data.title = title ? title.uuid : null

        var industry = industries.find(x => x.uuid === data.industry)
        if(!industry) {
            industry = industries.find(x => x.name === data.industry)
        }
        data.industry = industry ? industry.uuid : null

        data.startDate = data.startDate ? moment(data.startDate).format('YYYY-MM-DD') : null;
        data.endDate = data.endDate ? moment(data.endDate).format('YYYY-MM-DD') : null;

        if(data.stillWorkingHere) {
            data.endDate = null
        }

        return data
    }

    const startDateRef = useRef<any>(null);
    const endDateRef = useRef<any>(null);

    const allowCustomSubmit = () => {
        return customTechSkill && techSkills.filter(x => x.name.toLowerCase() === customTechSkill?.trim().toLowerCase())?.length === 0
    }

    const onSubmit: SubmitHandler<FormFields> = (data: any) => {

        setSelectedTechSkillsTouched(true)

        if(!techSkillsValid()) {
            return
        }

        data = processData(data)

        if(props.type === 'add') {
            dispatch(addRole({token: keycloak.token, uuid: profile?.uuid, payload: data}))
                .then((response) => {
                    if(response.meta.requestStatus === 'fulfilled') {
                        if(data.customTechSkills?.length > 0) {
                            dispatch(getTechSkills({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!, profileId: profile?.uuid}))
                        }
                        decisionModal.onOpen()
                    }
                })
        } else if(props.type === 'edit') {
            dispatch(editRole({token: keycloak.token, uuid: profile?.uuid, payload: data}))
                .then((response) => {
                    if(response.meta.requestStatus === 'fulfilled') {
                        if(data.customTechSkills?.length > 0) {
                            dispatch(getTechSkills({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!, profileId: profile?.uuid}))
                        }
                        navigate('/roles')  
                    }
                })
        } else if(props.type === 'initial') {
            dispatch(addRole({token: keycloak.token, uuid: profile?.uuid, payload: data}))
                .then((response) => {
                    if(response.meta.requestStatus === 'fulfilled') {
                        dispatch(updateProfileRoleAdded({roleAdded: true}))
                        if(data.customTechSkills?.length > 0) {
                            dispatch(getTechSkills({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!, profileId: profile?.uuid}))
                        }
                        decisionModal.onOpen()
                    }
                })
        }
    }

    return (
        (<Card className="p-8 mx-auto" shadow="sm">
            <Modal isOpen={decisionModal.isOpen} onOpenChange={decisionModal.onOpenChange} onClose={decisionModal.onClose} size="lg">
                <ModalContent>
                {(onClose) => (
                    <>
                    <ModalHeader className="flex flex-col gap-1">Your role has been added</ModalHeader>
                    <ModalBody className="min-h-[10em]">
                        <p className="leading-[2em]"> 
                            {platformName()} will be better able to generate matches for you if you add more roles. Would you like to add another role now?
                        </p>
                    </ModalBody>
                    <ModalFooter>

                        {props.type === 'add' && <Button variant="light" onPress={() => navigate('/roles')}>View roles</Button>}
                        {props.type === 'initial' &&<Button variant="light" onPress={() => navigate('/')}>View dashboard</Button>}
                        <Button 
                            color="success" 
                            onPress={() => { 
                                if(props.type === 'initial') {
                                    navigate('/add-role');
                                } else {
                                    reset(props.defaultValues)
                                    setCustomCompany('')
                                    setSelectedTechSkillsTouched(false)
                                    setSelectedTechSkills([])
                                    decisionModal.onClose()
                                }
                            }
                        }>
                            Add another role
                        </Button>
                    </ModalFooter>
                    </>
                )}
                </ModalContent>
            </Modal>
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-row flex-wrap w-full justify-between">
                    <div className="w-full lg:w-7/12">

                        <div className="flex flex-wrap mb-3 lg:mb-6">
                            <div className="w-full mb-3 lg:w-1/2 lg:mb-0 lg:pr-6">
                           
                                <div className={`controller-wrapper bg-default-100 text-foreground-500 rounded-medium${errors?.startDate ? ' error' : ''}`}>
                                    <label className="z-10 pointer-events-none subpixel-antialiased block cursor-text after:content-['*'] after:text-danger after:ml-0.5 text-small">From</label>
                                    <Controller
                                        control={control}
                                        name="startDate"
                                        defaultValue = {null}
                                        rules={{
                                            validate: {
                                                required: (value) => {
                                                    if (!value) return 'A valid start date is required'
                                                    if(value > now(getLocalTimeZone()).toDate()) {
                                                        return 'Start date cannot be in the future'
                                                    }
                                                },
                                                lessThan: (value) => {
                                                    var endDate = getValues().endDate
                                                    if(value != null && endDate != null && value > endDate) {
                                                        return 'Start date must be before end date'
                                                    }
                                                }
                                            }
                                        }}

                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                                <div className="flex justify-between" 
                                                    onClick={() => {
                                                        var cal = startDateRef?.current
                                                        if(cal) cal.focus()
                                                    }
                                                }>
                                                    <Calendar 
                                                        ref={startDateRef}
                                                        className="calendar" 
                                                        value={value} 
                                                        placeholder="mm / yyyy"
                                                        onChange={(event) => { onChange(event); }}
                                                        view="month" 
                                                        dateFormat="mm/yy" 
                                                        required={true} 
                                                        invalid={errors.startDate != null}
                                                        maxDate={today(getLocalTimeZone()).toDate(getLocalTimeZone())}
                                                        />
                                                    <svg aria-hidden="true" fill="none" focusable="false" height="1em" role="presentation" viewBox="0 0 24 24" width="1em" data-slot="selector-icon" className="text-lg text-default-400 pointer-events-none flex-shrink-0"><path d="M7.75 2.5a.75.75 0 0 0-1.5 0v1.58c-1.44.115-2.384.397-3.078 1.092c-.695.694-.977 1.639-1.093 3.078h19.842c-.116-1.44-.398-2.384-1.093-3.078c-.694-.695-1.639-.977-3.078-1.093V2.5a.75.75 0 0 0-1.5 0v1.513C15.585 4 14.839 4 14 4h-4c-.839 0-1.585 0-2.25.013z" fill="currentColor"></path><path d="M2 12c0-.839 0-1.585.013-2.25h19.974C22 10.415 22 11.161 22 12v2c0 3.771 0 5.657-1.172 6.828C19.657 22 17.771 22 14 22h-4c-3.771 0-5.657 0-6.828-1.172C2 19.657 2 17.771 2 14zm15 2a1 1 0 1 0 0-2a1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2a1 1 0 0 0 0 2m-4-5a1 1 0 1 1-2 0a1 1 0 0 1 2 0m0 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0m-6-3a1 1 0 1 0 0-2a1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2a1 1 0 0 0 0 2" fill="currentColor"></path></svg>
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                                {errors?.startDate && <span className="controller-error">{errors.startDate.message}</span>}
                            </div>
                            <div className="w-full lg:w-6/12">

                                <div className={`controller-wrapper bg-default-100 text-foreground-500 rounded-medium${errors?.endDate ? ' error' : ''}`}>
                                    <label className="z-10 pointer-events-none subpixel-antialiased block cursor-text after:content-['*'] after:text-danger after:ml-0.5 text-small">From</label>
                                    <Controller
                                        control={control}
                                        name="endDate"
                                        defaultValue = {null}
                                        rules={{
                                            validate: {
                                                required: (value) => {
                                                    if (!value && !getValues().stillWorkingHere) return 'A valid end date is required'
                                                    if(value && value > now(getLocalTimeZone()).toDate()) {
                                                        return 'End date cannot be in the future'
                                                    }
                                                }
                                            }
                                        }}

                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                                <div className="flex justify-between" 
                                                    onClick={() => {
                                                        var cal = endDateRef?.current
                                                        if(cal) cal.focus()
                                                    }
                                                }>
                                                    <Calendar 
                                                        ref={endDateRef}
                                                        className="calendar" 
                                                        value={value} 
                                                        placeholder="mm / yyyy"
                                                        onChange={(event) => { onChange(event); }}
                                                        dateFormat="mm/yy" 
                                                        view="month" 
                                                        disabled={stillWorkingHere === true}
                                                        required={true} 
                                                        invalid={errors.endDate != null}
                                                        maxDate={today(getLocalTimeZone()).toDate(getLocalTimeZone())}
                                                        />
                                                    <svg aria-hidden="true" fill="none" focusable="false" height="1em" role="presentation" viewBox="0 0 24 24" width="1em" data-slot="selector-icon" className="text-lg text-default-400 pointer-events-none flex-shrink-0"><path d="M7.75 2.5a.75.75 0 0 0-1.5 0v1.58c-1.44.115-2.384.397-3.078 1.092c-.695.694-.977 1.639-1.093 3.078h19.842c-.116-1.44-.398-2.384-1.093-3.078c-.694-.695-1.639-.977-3.078-1.093V2.5a.75.75 0 0 0-1.5 0v1.513C15.585 4 14.839 4 14 4h-4c-.839 0-1.585 0-2.25.013z" fill="currentColor"></path><path d="M2 12c0-.839 0-1.585.013-2.25h19.974C22 10.415 22 11.161 22 12v2c0 3.771 0 5.657-1.172 6.828C19.657 22 17.771 22 14 22h-4c-3.771 0-5.657 0-6.828-1.172C2 19.657 2 17.771 2 14zm15 2a1 1 0 1 0 0-2a1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2a1 1 0 0 0 0 2m-4-5a1 1 0 1 1-2 0a1 1 0 0 1 2 0m0 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0m-6-3a1 1 0 1 0 0-2a1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2a1 1 0 0 0 0 2" fill="currentColor"></path></svg>
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                                {errors?.endDate && <div className="controller-error">{errors.endDate.message}</div>}

                                <Controller
                                    control={control}
                                    name="stillWorkingHere"

                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Checkbox
                                            size="sm" 
                                            isSelected={value}
                                            onChange={onChange}
                                            className="pt-4 pb-8 lg:pb-0 lg:pt-8"
                                        >
                                            I'm still working here
                                        </Checkbox>
                                    )}
                                />

                            </div>
                        </div>
        
                        <div className="flex flex-wrap mb-3 lg:mb-6">
                            <div className="w-full mb-4 lg:w-1/2 lg:mb-0 lg:pr-6">
                                {getCompaniesStatus === 'loading' && <SelectLoading />}
                                {getCompaniesStatus === 'idle' && companies?.length > 0 && (
                                    <Controller
                                        control={control}
                                        name="company"
                                        rules={{
                                            validate: {
                                                required: (value) => {
                                                    if(!value && !customCompany) {
                                                        return 'Company is required'
                                                    }
                                                }
                                            }
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                                            <Autocomplete                     
                                                onInputChange={(val) => setCustomCompany(val)}
                                                ref={ref}
                                                value={value}
                                                selectedKey={value}
                                                onChange={onChange}
                                                onSelectionChange={onChange}
                                                onBlur={onBlur}
                                                allowsCustomValue
                                                label="Company"
                                                placeholder="Search for a company" 
                                                defaultItems={companies}
                                                isRequired={true}
                                                errorMessage="Please select a company"
                                                isInvalid={errors.company != null}
                                                >
                                                {(item) => <AutocompleteItem key={item.uuid}>{item.name}</AutocompleteItem>}
                                            </Autocomplete>
                                    )} />
                                )}
                            </div>
                            <div className="w-full lg:w-1/2">
                                {getTitlesStatus === 'loading' && <SelectLoading />}
                                {getTitlesStatus === 'idle' && titles?.length > 0 && (
                                    <Controller
                                        control={control}
                                        name="title"
                                        rules={{required: true}}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                                            <Autocomplete                     
                                                ref={ref}
                                                value={value}
                                                selectedKey={value}
                                                onSelectionChange={onChange}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                label="Title"
                                                placeholder="Search for your title" 
                                                defaultItems={titles}
                                                isRequired={true}
                                                errorMessage="Please select a title"
                                                isInvalid={errors.title != null}
                                            >
                                                {(item) => <AutocompleteItem key={item.uuid}>{item.name}</AutocompleteItem>}
                                            </Autocomplete>
                                    )} />
                                )}
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-3 lg:mb-6">
                            <div className="w-full mb-3 lg:w-1/2 lg:mb-0 lg:pr-6">
                                {getIndustriesStatus === 'loading' && <SelectLoading />}
                                {getIndustriesStatus === 'idle' && industries?.length > 0 && (
                                    <Controller
                                        control={control}
                                        name="industry"
                                        rules={{required: true}}
                                        render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                                            <Autocomplete
                                                ref={ref}
                                                value={value}
                                                selectedKey={value}
                                                onSelectionChange={onChange}
                                                label="Industry"
                                                placeholder="Select your industry"
                                                errorMessage={errors.industry && "Industry is required"}
                                                isRequired={true}
                                                isInvalid={errors.industry != null}
                                            >
                                                {industries.map((value) => (
                                                    <AutocompleteItem key={value.uuid}>
                                                        {value.name}
                                                    </AutocompleteItem>
                                                ))}
                                            </Autocomplete>
                                        )} />
                                )}
                            </div>
                            <div className="w-full lg:w-1/2">
                                <Controller
                                    control={control}
                                    name="workLocation"
                                    rules={{required: true}}
                                    render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                                         <Select
                                            ref={ref}
                                            onChange={onChange}
                                            value={value}
                                            selectedKeys={[value]}
                                            label="Work from X"
                                            placeholder="Select from where you are working"
                                            errorMessage={errors.workLocation && "Work from X is required"}
                                            isRequired={true}
                                            isInvalid={errors.workLocation != null}
                                        >
                                            <SelectItem key="ON_SITE">On-site</SelectItem>
                                            <SelectItem key="REMOTE">Remote</SelectItem>
                                            <SelectItem key="HYBRID">Hybrid</SelectItem>
                                        </Select> 
                                    )} />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="w-full mb-4">
                                {getTechSkillsStatus === 'loading' && <SelectLoading />}
                                {getTechSkillsStatus === 'idle' && (
                                    <div>
                                        <Modal isDismissable={false} isOpen={techSkillsModal.isOpen} onOpenChange={techSkillsModal.onOpenChange} size="2xl">
                                            <ModalContent>
                                                {() => (
                                                    <>
                                                    <ModalHeader className="flex flex-col gap-1">Add Tech skills</ModalHeader>
                                                    <ModalBody>
                                                        <Autocomplete                     
                                                            aria-label="Search for a tech skill"
                                                            allowsCustomValue={true}
                                                            startContent={<SearchIcon className="text-default-400" strokeWidth={2.5} size={20} />}
                                                            placeholder="Search for a skill" 
                                                            defaultItems={techSkills.filter((x) => {
                                                                return selectedTechSkills.find((y) => y['uuid'] === x['uuid']) === undefined
                                                            })}
                                                            onSelectionChange={(value) => {
                                                                if(value) {
                                                                    selectTechSkill(value.toString())
                                                                }
                                                            }}
                                                            onInputChange={(value) => {
                                                                setCustomTechSkill(value)
                                                            }}
                                                            >
                                                            {(item) => <AutocompleteItem key={item.uuid}>{item.name}</AutocompleteItem>}
                                                        </Autocomplete>

                                                        {allowCustomSubmit() && (
                                                            <span className="flex justify-content pt-2 pb-4">
                                                                <span className="text-small pt-1.5 pb-2 pr-2">We can't find the tech skill <span className="font-semibold">"{customTechSkill}"</span>, would you like to add it?</span>
                                                                <span className="text-small">
                                                                    <Button size="sm" color="success" isIconOnly={true} className="text-white rounded-full" onPress={() => {
                                                                        addCustomTechSkill(customTechSkill)
                                                                    }}>
                                                                        <PlusIcon />
                                                                    </Button>
                                                                </span>
                                                            </span>
                                                        )}
                                 
                                                        <span className="font-semibold mt-8 mb-2">Selected Tech skills</span>

                                                        {selectedTechSkills?.length === 0 && <span className="text-small">No skills selected yet</span>}

                                                        <div className="flex flex-wrap gap-2 pb-4">
                                                            {selectedTechSkills.map((x : TechSkill) => (
                                                                <Chip

                                                                    color="primary" 
                                                                    className="mb-2"
                                                                    key={x.uuid}
                                                                    onClose={() => {
                                                                        deselectTechSkill(x?.uuid)
                                                                    }}
                                                                >
                                                                    {x.name}
                                                                </Chip>
                                                            ))}
                                                        </div>

                                                        {techSkills.filter((x) => selectedTechSkills.find((y) => y['uuid'] === x['uuid']) === undefined).length > 0 && <span className="font-semibold mt-8 mb-2">Suggested Tech skills</span>}

                                                        <div className="flex flex-wrap gap-2 pb-4">
                                                            {techSkills.filter((x) => selectedTechSkills.find((y) => y['uuid'] === x['uuid']) === undefined).map((x : TechSkill) => (
                                                                <div 
                                                                    className="relative max-w-fit min-w-min inline-flex items-center justify-between box-border whitespace-nowrap px-1 h-7 text-small rounded-full bg-default text-default-foreground mb-2"
                                                                    key={x.uuid}
                                                                >
                                                                    <span 
                                                                        className="flex cursor-pointer px-2 pr-1"
                                                                        onClick={(e) => {
                                                                            selectTechSkill(x.uuid.toString())
                                                                        }}
                                                                    >
                                                                        {x.name} <ChipPlusIcon />
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>

                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button  
                                                            color="primary"
                                                            onPress={(e) => {
                                                                techSkillsModal.onClose()
                                                            }}
                                                        >
                                                            I'm done adding skills
                                                        </Button>
                                                    </ModalFooter>
                                                    </>
                                                )}
                                            </ModalContent>
                                        </Modal>
                                        <label 
                                        className={`pl-2 mt-4 mb-6 z-10 pointer-events-none subpixel-antialiased block ${selectedTechSkillsTouched !== true || !techSkillsError() ? 'text-foreground-500' : 'text-danger'} cursor-text after:content-['*'] after:text-danger after:ml-0.5 text-xs`}>Tech skills</label>

                                        <div className="flex flex-wrap gap-2 pl-2 pb-4">

                                            {selectedTechSkills?.map((value: any) => (
                                                <Chip 
                                                    color="primary" 
                                                    className="mb-2"
                                                    key={value.uuid}
                                                    onClose={() => {
                                                        deselectTechSkill(value?.uuid)
                                                    }}
                                                >
                                                    {value?.name}
                                                </Chip>
                                            ))}
                                            <Chip 
                                                className="cursor-pointer" 
                                                color={undefined}
                                                onClick={() => {
                                                    techSkillsModal.onOpen()
                                                }}
                                            >
                                                <span className="flex">Add skills <ChipPlusIcon /></span>
                                            </Chip>                        
                                            {selectedTechSkillsTouched === true && techSkillsError() && (
                                                <span className="form-error inline">{techSkillsError()}</span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="w-full pt-12 lg:pt-0 lg:pl-20 lg:w-5/12 flex-column">

                        <Controller
                            control={control}
                            name="whatDidYouBuild"

                            rules={{required: true}}
                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <Textarea
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    label="What did you build?"
                                    className="max-w mb-6"
                                    isRequired={true}
                                    isInvalid={errors.whatDidYouBuild != null}
                                    errorMessage={errors.whatDidYouBuild && "What did you build is required"}
                                ></Textarea>
                            )} />

                        <Controller
                            control={control}
                            name="impactToTheBusiness"
                            rules={{required: true}}
                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <Textarea
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    label="How did this impact the business?"
                                    className="max-w mb-6"
                                    isRequired={true}
                                    isInvalid={errors.impactToTheBusiness != null}
                                    errorMessage={errors.impactToTheBusiness && "How did this impact the business is required?"}
                                ></Textarea>
                            )} />

                        <Controller
                            control={control}
                            name="teamSizeAndRole"
                            rules={{required: true}}
                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <Textarea
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    label="Team size, and your role with the team?"
                                    className="max-w mb-6"
                                    isRequired={true}
                                    isInvalid={errors.teamSizeAndRole != null}
                                    errorMessage={errors.teamSizeAndRole && "Team size, and your role is required"}
                                ></Textarea>
                        )} />

                        <Controller
                            control={control}
                            name="techEnvironment"
                            rules={{required: true}}
                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <Textarea
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    label="What was the tech environment?"
                                    className="max-w"
                                    isRequired={true}
                                    isInvalid={errors.techEnvironment != null}
                                    errorMessage={errors.techEnvironment && "Tech environment is required"}
                            ></Textarea>
                        )} />

                    </div>    
                </div>
                <div className="flex flex-wrap w-full pt-12">
                    {props.type !== 'initial' && <Button className="bg-transparent font-semibold py-2 px-4 pl-0 mr-6 hover:border-transparent" onPress={() => navigate('/roles')}>Cancel</Button>}
                    <Button color="primary" type="submit">
                        {props.type === 'add' ? 'Add role' : props.type === 'edit' ? 'Save role details' : 'Continue'}
                        {(addRoleStatus === 'loading' || editRoleStatus === 'loading') && <Spinner size="sm" color="default" />}  
                        {(addRoleStatus !== 'loading' && editRoleStatus !== 'loading') && <RightArrowIcon />}
                    </Button>
                    {(addRoleStatus === 'failed' || editRoleStatus === 'failed') && <span className="form-error block md:inline">Error received from the server, please try again</span>}
                </div>
            </form>
        </Card>)
    );
}