
import {Button} from "@heroui/button";
import { Card } from "@heroui/card";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks";
import { useKeycloak } from "@react-keycloak/web";
import { SubmitHandler, useForm } from "react-hook-form";
import { Checkbox, Input, Spinner, cn } from "@heroui/react";

import { useNavigate } from "react-router-dom";
import { getProfile } from "../../../../features/profiles/profilesSlice";
import RightArrowIcon from "../../common/icons/rightArrowIcon";
import { addHiringTeamMember, editHiringTeamMember, getCompany, updateActions } from "../../../../features/companies/companiesSlice";

type FormFields = {
    emailAddress: string,
    superUser: boolean,
    payRates: boolean,
    location: boolean,
    duration: boolean,
    workLocation: boolean
}

export default function HiringTeamMemberForm(props: any) {

    const dispatch = useAppDispatch()
    let navigate = useNavigate()

    const { keycloak, initialized } = useKeycloak()

    const profile = useAppSelector(state => state.profiles.profile)

    const company = useAppSelector(state => state.companies.company)

    const editMemberStatus = useAppSelector(state => state.companies.editHiringTeamMemberStatus)
    const addMemberStatus = useAppSelector(state => state.companies.addHiringTeamMemberStatus)

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },        
    } = useForm<FormFields>({
        mode: 'onBlur',
        defaultValues: props.defaultValues
    })

    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 
    
    useEffect(() => {
        if (keycloak.authenticated) {

            if(!profile) {
                dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
                .then((response) => {
                  var payload = {token: keycloak.token, uuid: response.payload.uuid}
                  dispatch(getCompany(payload))
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])

    const processData = (data: any) : any => {

        data.visibilities = []

        if(data['payRates'] === true) data['visibilities'].push('PAY_RATES')
        if(data['location'] === true) data['visibilities'].push('LOCATION')
        if(data['duration'] === true) data['visibilities'].push('DURATION')
        if(data['workLocation'] === true) data['visibilities'].push('WORK_LOCATION')

        return data
    }

    const onSubmit: SubmitHandler<FormFields> = (data: any) => {

        data = processData(data)

        if(!data['visibilities'] || data['visibilities'].length === 0) {
            setError('payRates', {type: 'required', message: 'At least one visibilty is required'})
        } else {
            clearErrors('payRates')
        }

        if(props.type === 'add') {
            dispatch(addHiringTeamMember({token: keycloak.token, uuid: company?.uuid, payload: data}))
                .then((response) => {
                    if(response.meta.requestStatus === 'fulfilled') {

                        if(!company) return
            
                        var c = structuredClone(company)
                        
                        if(!c.actions) c.actions = {}
                        c.actions['addHiringTeam'] = false
            
                        dispatch(updateActions(c.actions))
            
                        navigate('/hiring-team')  
                    } else {
                        var message = response?.payload?.message
                        if(message) {
                            setError('root', {type: 'request', message: message})
                        }
                    }
                })
        } else if(props.type === 'edit') {
            dispatch(editHiringTeamMember({token: keycloak.token, companyId: company?.uuid, uuid: props.defaultValues.uuid, payload: data}))
                .then((response) => {
                    if(response.meta.requestStatus === 'fulfilled') {
                        navigate('/hiring-team')  
                    } else {
                        var message = response?.payload?.message
                        if(message) {
                            setError('root', {type: 'request', message: message})
                        }
                    }
                })
        }
    }

    return (

        <Card className="p-8 mx-auto" shadow="sm">
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-row flex-wrap w-full justify-between">
                    <div className="w-full lg:w-7/12">

        
                        <div className="flex flex-wrap mb-3 lg:mb-6">
                            <div className="w-full mb-4 lg:w-1/2 lg:mb-0 lg:pr-6">

                                {(props.type !== 'add' && props.defaultValues.archived !== undefined) && (
                                    <div>
                                        <span className="text-foreground-500 block pb-2">Member name</span>
                                        {props.defaultValues.firstName} {props.defaultValues.lastName}
                                    </div>
                                )}

                                {(props.type === 'add' || props.defaultValues.archived === undefined) && (
                                    <Input
                                        label="Email address"
                                        placeholder="Enter the email address"
                                        isRequired={true}
                                        type="email"
                                        onClear={() => console.log("clear")}
                                        errorMessage={errors.emailAddress && "Email address is required"}
                                        isInvalid={errors.emailAddress != null}
                                        {...register("emailAddress", {required: true})} />
                                )}
                            </div>
                        </div>

                        <div className="flex-wrap mb-3 lg:mb-6">


                            {errors.payRates?.type === 'required' && <div className="p-1 pl-0 text-tiny text-danger">At least one option is required</div>}

                            <span className="text-foreground-500 block pb-2">Select what the member can see</span>
                            
                            <Checkbox className="block pb-2" {...register("payRates")}>Pay rates</Checkbox>
                            <Checkbox className="block pb-2" {...register("location")}>Location</Checkbox>
                            <Checkbox className="block pb-2" {...register("duration")}>Contract duration</Checkbox>
                            <Checkbox className="block pb-2" {...register("workLocation")}>Work location</Checkbox>

                        </div>
                      
                    </div>
                    <div className="w-full pt-12 lg:pt-0 lg:pl-20 lg:w-5/12 flex-column">


                        <div className="flex-wrap mb-3 lg:mb-6">

                            {errors.superUser?.type === 'required' && <div className="p-1 pl-0 text-tiny text-danger">At least one visibility is required</div>}

                            <span className="text-foreground-500 block pb-2">Administrator access</span>
                            
                            <div className="w-full flex items-center">
                                <div role="alert" data-visible="true" data-has-title="true" title="This is a danger alert" className="flex flex-grow flex-row w-full py-3 px-4 gap-x-1 rounded-medium items-start bg-danger-50 dark:bg-danger-50/50">
                                    <div className="h-full flex-grow min-h-10 ms-2 flex flex-col box-border text-inherit justify-center items-center">
                                        <div className="text-small w-full font-medium block text-inherit leading-5">
                                        <Checkbox 
                                            classNames={{
                                                base: cn(
                                                "inline-flex w-full max-w-md",
                                                "items-center justify-start text-inherit",
                                            )}}
                                            
                                            {...register("superUser")}>
                                                <div className="w-full pl-4 text-danger-600 dark:text-danger-500">
                                                    <span className="block font-bold pb-4">WARNING</span>
                                                    <span>Selecting this option will give the team member complete access to your company.</span>
                                                    <span className="block pt-4 font-bold">Only select this option if you require the member to have full administrator access.</span>
                                                </div>
                                            </Checkbox>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>                    
                    </div>    
                </div>
                <div className="flex flex-wrap w-full pt-12">
                    {props.type !== 'initial' && <Button className="bg-transparent font-semibold py-2 px-4 pl-0 mr-6 hover:border-transparent" onClick={() => navigate('/hiring-team')}>Cancel</Button>}
                    <Button color="primary" type="submit">
                        {props.type === 'add' ? 'Add member' : props.type === 'edit' ? 'Edit member' : 'Continue'}
                        {(addMemberStatus === 'loading' || editMemberStatus === 'loading') && <Spinner size="sm" color="default" />}  
                        {(addMemberStatus !== 'loading' && editMemberStatus !== 'loading') && <RightArrowIcon />}
                    </Button>
                    {(addMemberStatus === 'failed' || editMemberStatus === 'failed') && (
                        <span className="form-error block md:inline">
                            {errors.root?.message && <span>{errors.root.message}</span>}
                            {!errors.root?.message && <span>Error received from the server, please try again</span>}
                        </span>
                    )}
                </div>
            </form>
        </Card>
    );
}