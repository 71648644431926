import Keycloak from 'keycloak-js'
import { Company } from '../features/companies/companiesSlice'

export const canManageCompanies = (keycloak: Keycloak) : boolean => {
  return keycloak?.tokenParsed?.realm_access?.roles.find((obj, key) => obj.toLocaleLowerCase() === 'admin_manage_companies') !== undefined
}

export const isCompanyUser = (keycloak: Keycloak) : boolean => {
  return keycloak?.tokenParsed?.realm_access?.roles.find((obj, key) => obj.toLocaleLowerCase() === 'company') !== undefined
}

export const canManageCompany = (keycloak: Keycloak, company: Company) : boolean => {
  if(!keycloak || !company || !company.users) {
    return false
  }
  return company.users.filter(x => x.superUser === true && x.uuid === keycloak.tokenParsed?.sub).length > 0
}
