
import {Button} from "@heroui/button"
import {Input, Textarea} from "@heroui/input"
import { Card } from "@heroui/card"
import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks"
import { useKeycloak } from "@react-keycloak/web"
import { updateProfileCompleted } from "../../../../features/profiles/profilesSlice"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

import 'react-phone-number-input/style.css'
import RightArrowIcon from "../../common/icons/rightArrowIcon"
import { Spinner } from "@heroui/react"
import { useDropzone } from "react-dropzone"
import { createCompany } from "../../../../features/companies/companiesSlice"
import DeleteImageIcon from "../../common/icons/deleteImageIcon"
import { uploadFile } from "../../../../features/files/filesSlice"

type FormFields = {
    name: string
    logo: File | null
    description: string
}

export default function InitialCompanyForm() {

    const dispatch = useAppDispatch()
    const { keycloak } = useKeycloak()

    const profile = useAppSelector(state => state.profiles.profile)
    const status = useAppSelector(state => state.companies.createCompanyStatus)

    const uploadStatus = useAppSelector(state => state.files.uploadFileStatus)

    const [files, setFiles] = useState<any[]>([]);
    
    const maxFileSize = 2000000;

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/*': []
        },
        maxFiles: 1,
        onDrop: acceptedFiles => {
            setValue("logo", acceptedFiles[0])

            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }   
    });

    const preview = {
        display: 'block',
        width: 'auto'
    };
      
    const removeFile = () => {
        setFiles([])
        setValue('logo', null)
    }

    const {
        control,
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        formState: { errors },        
    } = useForm<FormFields>({
        mode: 'onBlur',
        defaultValues: {
            name: '',
            logo: null,
            description: profile?.candidateProfile?.description
        }
    })

    const onSubmit: SubmitHandler<FormFields> = (data: any) => {

        if(files && files.length > 0) {
            if(files[0].size > maxFileSize) {
                setError('logo', {type: 'maxSize', message: `The maximum allowed file size is ${maxFileSize/1000000}MB`})
            } else {
                clearErrors('logo')
            }

            const fileData = new FormData();
            fileData.append("file", files[0])
            dispatch(uploadFile({token: keycloak.token, payload: {file: fileData}}))
                .then((response) => {
                    if(response.meta.requestStatus === 'fulfilled') {
                        data['logo'] = response.payload.filename

                        dispatch(createCompany({token: keycloak.token, payload: data}))
                            .then((response) => {   
                                if(response.meta.requestStatus === 'fulfilled') {
                                    dispatch(updateProfileCompleted({completed: true}))
                                }
                            })
                    }
                })

        } else {
            data['logo'] = null

            dispatch(createCompany({token: keycloak.token, payload: data}))
                .then((response) => {   
                    if(response.meta.requestStatus === 'fulfilled') {
                        dispatch(updateProfileCompleted({completed: true}))
                    }
                })
        }
    }

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);
    
    
    return (
        <div className="w-full min-h-screen bg-zinc-50 content-center">

            <h1 className="text-center text-2xl font-bold w-full pt-12 xl:pt-0">{keycloak.tokenParsed?.given_name}, tell us more about your company</h1>

            <div className="container mx-auto pt-24 pb-24">
                <Card className="w-10/12 sm:w-full max-w-2xl p-8 mx-auto">
                    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                        <div className="w-full">
                            <Input
                                label="Company name"
                                placeholder="Enter your company name"
                                isRequired={true}
                                onClear={() => console.log("clear")}
                                errorMessage={errors.name && "Company name is required"}
                                isInvalid={errors.name != null}
                                {...register("name", {required: true})} />
                        </div>
                        <div className="w-full">
                            <Textarea
                                label="About your company"
                                minRows={5}
                                placeholder="Tell us about your company"
                                isRequired={true}
                                onClear={() => console.log("clear")}
                                className="ml-auto w-full mt-6 mb-6 lg:mb-12"
                                errorMessage={errors.description && "Description is required"}
                                isInvalid={errors.description != null}
                                {...register("description", {required: true})} />

                        </div>    
                        <div className="w-full">
                            <Controller
                                render={({ field: { onChange } }) => (
                                    <>
                                    <label className={`mt-4 pb-1.5 z-10 pointer-events-none subpixel-antialiased block ${!errors.logo ? 'text-foreground-500' : 'text-danger'} cursor-text text-small`}>
                                           Company logo</label>

                                    <section className="container drop-container flex-row">
                                        <div {...getRootProps({className: `dropzone${errors.logo ? ' error text-danger' : ''}${files.length === 0 ? ' w-full' : ' w-3/4'}`})}>
                                            <input {...getInputProps( { onChange })} />
                                            <p>Drag 'n' drop an image here, or click to select a file</p>
                                            <p className="pt-2">(Max file size {maxFileSize/1000000}MB)</p>
                                        </div>
                                        {files.length > 0 && (
                                            <div className="w-1/4 pl-4 relative">
                                                {files.map((file, index) => (
                                                    <React.Fragment key={index}>
                                                        <span 
                                                            className="absolute top-0 right-0 -mt-3 -mr-3 text-danger shadow-lg drop-shadow-lg cursor-pointer"
                                                            onClick={removeFile}
                                                        >
                                                            <DeleteImageIcon />
                                                        </span>
                                                        <img
                                                            alt="Company logo" 
                                                            src={file.preview} 
                                                            style={preview} 
                                                            onLoad={() => { URL.revokeObjectURL(file.preview) }} 
                                                        />
                                                    </React.Fragment>
                                                ))}
                                            </div> 
                                        )}
                                    </section>
                                    {errors.logo?.type === 'required' && <div className="p-1 text-tiny text-danger">Logo is required</div>}
                                    {errors.logo?.type === 'maxSize' && <div className="p-1 text-tiny text-danger">The maximum allowed file size is {maxFileSize/1000000}MB</div>}
                                    </>
                                )}
                                name="logo"
                                control={control}
                            />

                        </div>
                        <div className="flex flex-wrap w-full pt-12">
                            <Button color="primary" type="submit">
                                Continue
                                {(status === 'loading' || uploadStatus === 'loading') && <Spinner size="sm" color="default" />} 
                                {status !== 'loading' && uploadStatus !== 'loading' && <RightArrowIcon />}
                            </Button>
                            {(status === 'failed' || uploadStatus === 'failed') && <span className="form-error block md:inline">Error received from the server, please try again</span>}
                        </div>

                    </form>
                </Card>
            </div>
        </div>
    )
};