
import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks"
import { useKeycloak } from "@react-keycloak/web"
import { getProfile } from "../../../../features/profiles/profilesSlice"
import { getCompany, HiringTeamMember } from "../../../../features/companies/companiesSlice"
import { canManageCompany } from "../../../../lib/company"
import { useNavigate, useParams } from "react-router-dom"
import HiringTeamLoading from "./loading"
import HiringTeamError from "./error"
import AuthLoading from "../../common/authLoading"
import { Navigation } from "../../common/navigation"
import HiringTeamMemberForm from "./form"
import { BreadcrumbItem, Breadcrumbs } from "@heroui/react"
import PeopleIcon from "../../common/icons/peopleIcon"
import HomeIcon from "../../common/icons/homeIcon"

export default function EditHiringTeamMemberForm() {

    const { keycloak, initialized } = useKeycloak()
    let navigate = useNavigate()
    const dispatch = useAppDispatch()
    let { id } = useParams();

    const profile = useAppSelector(state => state.profiles.profile)
    const getProfileStatus = useAppSelector(state => state.profiles.getProfileStatus)

    const company = useAppSelector(state => state.companies.company)
    const getCompanyStatus = useAppSelector(state => state.companies.getCompanyStatus)

    const [ user, setUser ] = useState<HiringTeamMember | undefined>(undefined)

    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 

    useEffect(() => {
        if (keycloak.authenticated && !keycloak.userInfo) {

            if(!profile) {
                dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
            }
            if(!company) {
                dispatch(getCompany({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
            }
        }
  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])

    useEffect(() => {

        if(!company || !profile) return

        var x = company?.users?.find(x => x.uuid === id)
        if(x === undefined) x = company?.invitedUsers?.find(x => x.uuid === id)

        setUser(x)

        if(!canManageCompany(keycloak, company)) {
            navigate('/')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company, profile])

    return initialized && keycloak.authenticated ? (
        <>
          {(getProfileStatus === 'loading' || getCompanyStatus === 'loading') && (
            <HiringTeamLoading />
          )}
          {(getProfileStatus === 'failed' || getCompanyStatus === 'failed') && (
            <HiringTeamError />
          )}
          {getProfileStatus === 'idle' && getCompanyStatus === 'idle' && profile && company && user && (
                <div className="bg-zinc-50 min-h-screen">
                    <Navigation />
                    <div className="ml-5 mr-5 pt-8 sm:container sm:mx-auto">
                
                        <Breadcrumbs className="pt-3 mb-12">
                            <BreadcrumbItem onPress={x => navigate('/')}><HomeIcon />Home</BreadcrumbItem>
                            <BreadcrumbItem onPress={x => navigate('/hiring-team')}><PeopleIcon />Hiring team</BreadcrumbItem>
                            <BreadcrumbItem>Edit member</BreadcrumbItem>
                        </Breadcrumbs>
        
                        <HiringTeamMemberForm 
                            type="edit"
                            defaultValues={{
                                uuid: user?.uuid,
                                firstName: user?.firstName,
                                lastName: user?.lastName,
                                emailAddress: user?.emailAddress,
                                superUser: user?.superUser,
                                payRates: user?.visibility?.find(x => x === 'PAY_RATES') !== undefined,
                                location: user?.visibility?.find(x => x === 'LOCATION') !== undefined,
                                duration: user?.visibility?.find(x => x === 'DURATION') !== undefined,
                                workLocation: user?.visibility?.find(x => x === 'WORK_LOCATION') !== undefined,
                                archived: user?.archived
                            }}
                        />
        
                    </div>
                </div>
            )}
        </>
    ) : <AuthLoading />
}