
import {Button} from "@heroui/button"
import {Input, Textarea} from "@heroui/input"
import { Card } from "@heroui/card"
import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/hooks"
import { useKeycloak } from "@react-keycloak/web"
import { getProfile } from "../../../features/profiles/profilesSlice"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

import { Navigation } from "../common/navigation"
import { BreadcrumbItem, Breadcrumbs, Spinner } from "@heroui/react"
import HomeIcon from "../common/icons/homeIcon"
import { useNavigate } from "react-router-dom"
import AuthLoading from "../common/authLoading"
import RightArrowIcon from "../common/icons/rightArrowIcon"
import MyCompanyLoading from "./loading"
import MyCompanyError from "./error"
import BuildingIcon from "../common/icons/buildingIcon"
import { getCompany, updateCompany } from "../../../features/companies/companiesSlice"
import { useDropzone } from "react-dropzone"
import DeleteImageIcon from "../common/icons/deleteImageIcon"
import { uploadFile } from "../../../features/files/filesSlice"
import { canManageCompany } from "../../../lib/company"

export default function EditCompanyForm() {

    const { keycloak, initialized } = useKeycloak()
    const dispatch = useAppDispatch()
    let navigate = useNavigate()

    const profile = useAppSelector(state => state.profiles.profile)
    const company = useAppSelector(state => state.companies.company)
    const getCompanyStatus = useAppSelector(state => state.companies.getCompanyStatus)

    // TODO Centralise this somehow
    if(!keycloak.authenticated && initialized) {
        keycloak?.login()
    } 

    useEffect(() => {
        if (keycloak.authenticated && !keycloak.userInfo) {
    
            if(!profile) {
                dispatch(getProfile({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
            }
            if(!company) {
                dispatch(getCompany({token: keycloak.token!, uuid: keycloak.tokenParsed!.sub!}))
            }
        }
  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak, keycloak.authenticated])

    useEffect(() => {
        if (!company) return

        if(!canManageCompany(keycloak, company)) {
            navigate('/')
        }
  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company])

    return initialized && keycloak.authenticated ? (
        <>
          {getCompanyStatus === 'loading' && (
            <MyCompanyLoading />
          )}
          {getCompanyStatus === 'failed' && (
            <MyCompanyError />
          )}
          {getCompanyStatus === 'idle' && company && (
            <EditCompanyFormActual />
          )}
        </>
    ) : <AuthLoading />
}

function EditCompanyFormActual() {

    type FormFields = {
        name: string
        description: string
        logo: File | null
    }
      
    const dispatch = useAppDispatch()
    const { keycloak } = useKeycloak()
    let navigate = useNavigate()

    const company = useAppSelector(state => state.companies.company)
    const status = useAppSelector(state => state.companies.updateCompanyStatus)

    const uploadStatus = useAppSelector(state => state.files.uploadFileStatus)

    const [files, setFiles] = useState<any[]>([]);
    
    const maxFileSize = 2000000;

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/*': []
        },
        maxFiles: 1,
        onDrop: acceptedFiles => {
            setValue("logo", acceptedFiles[0])

            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }   
    });

    const preview = {
        display: 'block',
        width: 'auto'
    };
      
    const removeFile = () => {
        setFiles([])
        setValue('logo', null)
    }

    const {
        control,
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        formState: { errors },        
    } = useForm<FormFields>({
        mode: 'onBlur',
        defaultValues: {
            name: company?.name,
            description: company?.description,
            // logo: company?.logo ? new File(company.logo)
        }
    })

    useEffect(() => {
        if(!company) return
        if(company.logo) {
            fetch(`${process.env.REACT_APP_IMAGE_PATH}${company.logo}`)
                .then(res => res.blob())
                .then(blob => {
                  const file = new File([blob], company.logo, blob)
                  setFiles([Object.assign(file, {
                    preview: URL.createObjectURL(file)
                  })])
                  setValue('logo', file)
            })       
        }
             
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company])

    const onSubmit: SubmitHandler<FormFields> = (data: any) => {

        data['uuid'] = company?.uuid

        if(files && files.length > 0) {
    
            if(files[0].size > maxFileSize) {
                setError('logo', {type: 'maxSize', message: `The maximum allowed file size is ${maxFileSize/1000000}MB`})
            } else {
                clearErrors('logo')
            }


            // Logo hasn't changed
            if(company?.logo === data['logo']['name']) {
                data['logo'] = company?.logo
                dispatch(updateCompany({token: keycloak.token, payload: data}))
                .then((response) => {
                    if(response.meta.requestStatus === 'fulfilled') {
                        navigate('/')  
                    }
                })
                return
            }

            const fileData = new FormData();
            fileData.append("file", files[0])
            dispatch(uploadFile({token: keycloak.token, payload: {file: fileData}}))
                .then((response) => {
                    if(response.meta.requestStatus === 'fulfilled') {
                        data['logo'] = response.payload.filename

                        dispatch(updateCompany({token: keycloak.token, payload: data}))
                        .then((response) => {
                            if(response.meta.requestStatus === 'fulfilled') {
                                navigate('/')  
                            }
                        })
                    }
                })

        } else {
            data['logo'] = null

            dispatch(updateCompany({token: keycloak.token, payload: data}))
                .then((response) => {
                    if(response.meta.requestStatus === 'fulfilled') {
                        navigate('/')  
                    }
                })
        }
    }
      
    return (
    
        <div className="bg-zinc-50 min-h-screen">
            <Navigation />
            <div className="ml-5 mr-5 pt-8 sm:container sm:mx-auto">
        
                <Breadcrumbs className="pt-3 mb-11">
                    <BreadcrumbItem onPress={x => navigate('/')}><HomeIcon />Home</BreadcrumbItem>
                    <BreadcrumbItem onPress={x => navigate('/')}><BuildingIcon />My company</BreadcrumbItem>
                    <BreadcrumbItem>Edit company</BreadcrumbItem>
                </Breadcrumbs>

                <Card className="p-8 mx-auto" shadow="sm">
                    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-row flex-wrap w-full justify-between">

                            <div className="w-full lg:w-6/12">

                                <div className="w-full">
                                    <Input
                                        label="Company name"
                                        placeholder="Enter your company name"
                                        isRequired={true}
                                        onClear={() => console.log("clear")}
                                        errorMessage={errors.name && "Company name is required"}
                                        isInvalid={errors.name != null}
                                        {...register("name", {required: true})} />
                                </div>
                                <div className="w-full">
                                    <Textarea
                                        label="About your company"
                                        minRows={5}
                                        placeholder="Tell us about your company"
                                        isRequired={true}
                                        onClear={() => console.log("clear")}
                                        className="ml-auto w-full mt-6 mb-6 lg:mb-12"
                                        errorMessage={errors.description && "Description is required"}
                                        isInvalid={errors.description != null}
                                        {...register("description", {required: true})} />

                                </div>    
                            </div>

                            
                            <div className="w-full pt-12 lg:pt-0 lg:pl-20 lg:w-6/12 flex-column">

                                <div className="w-full">
                                   <Controller
                                        render={({ field: { onChange } }) => (
                                            <>
                                            <label className={`pb-1.5 z-10 pointer-events-none subpixel-antialiased block ${!errors.logo ? 'text-foreground-500' : 'text-danger'} cursor-text text-small`}>
                                                Company logo</label>

                                            <section className="container drop-container flex-row">
                                                <div {...getRootProps({className: `dropzone${errors.logo ? ' error text-danger' : ''}${files.length === 0 ? ' w-full' : ' w-3/4'}`})}>
                                                    <input {...getInputProps( { onChange })} />
                                                    <p>Drag 'n' drop an image here, or click to select a file</p>
                                                    <p className="pt-2">(Max file size {maxFileSize/1000000}MB)</p>
                                                </div>
                                                {files.length > 0 && (
                                                    <div className="w-1/4 pl-4 relative">
                                                        {files.map((file, index) => (
                                                            <React.Fragment key={index}>
                                                                <span 
                                                                    className="absolute top-0 right-0 -mt-3 -mr-3 text-danger shadow-lg drop-shadow-lg cursor-pointer"
                                                                    onClick={removeFile}
                                                                >
                                                                    <DeleteImageIcon />
                                                                </span>
                                                                <img
                                                                    alt="Company logo" 
                                                                    src={file.preview} 
                                                                    style={preview} 
                                                                    onLoad={() => { URL.revokeObjectURL(file.preview) }} 
                                                                />
                                                            </React.Fragment>
                                                        ))}
                                                    </div> 
                                                )}
                                            </section>
                                            {errors.logo?.type === 'required' && <div className="p-1 text-tiny text-danger">Logo is required</div>}
                                            {errors.logo?.type === 'maxSize' && <div className="p-1 text-tiny text-danger">The maximum allowed file size is {maxFileSize/1000000}MB</div>}
                                            </>
                                        )}
                                        name="logo"
                                        control={control}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap w-full pt-6">
                            <Button className="bg-transparent font-semibold py-2 px-4 pl-0 mr-6 hover:border-transparent" onClick={() => navigate('/')}>Cancel</Button>
                            <Button color="primary" type="submit">
                                Save company
                                {(status === 'loading' || uploadStatus === 'loading') && <Spinner size="sm" color="default" />} 
                                {status !== 'loading' && uploadStatus !== 'loading' && <RightArrowIcon />}
                            </Button>
                            {status === 'failed' && <span className="form-error block md:inline">Error received from the server, please try again</span>}
                        </div>

                    </form>
                </Card>
            </div>
        </div>
    );
}