import * as React from "react";
import { useKeycloak } from "@react-keycloak/web"
import "../../../../globals.css"
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip, useDisclosure } from "@heroui/react";
import moment from 'moment';
import { useState } from "react";
import useSWR from "swr";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import ViewIcon from "../../common/icons/viewIcon";
import RightArrowIcon from "../../common/icons/rightArrowIcon";
import ApproveIcon from "../../common/icons/approveIcon";
import { approveCompany, Company, HiringTeamMember } from "../../../../features/companies/companiesSlice";

interface Response {
  count: number
  results: Company[]
}

export function AdminCompaniesListTable() {

    const { keycloak } = useKeycloak()
    let navigate = useNavigate()

    const dispatch = useAppDispatch()
    const approveCompanyStatus = useAppSelector(state => state.companies.approveCompanyStatus)

    const fetcher = async function fetcher<JSON = any>(
      input: RequestInfo,
      init?: RequestInit
    ): Promise<JSON> {
      const res = await fetch(input, {
        method: "GET",
        headers: { "Authorization": "Bearer " + keycloak.token, "Content-Type": "application/json" }
      })
      return res.json()
    }
    
    const {isOpen, onOpen, onOpenChange} = useDisclosure()
    const [ selectedCompany, setSelectedCompany ] = useState('')

    const [page, setPage] = React.useState(1);

    const rowsPerPage = 20;

    const {data, isLoading} = useSWR<Response, string>(`${process.env.REACT_APP_COMPANIES_API_URL}companies?page=${page}&rowsPerPage=${rowsPerPage}`, fetcher, {
      keepPreviousData: true,
    });

    const getKeyValue = (object: any, key: any) => {
      switch(key) {
        case "dateCreated":
          return moment(object[key]).format('Do MMMM, YYYY')
        case "name":
          return object['name']
        case "phoneNumber":
          var user : HiringTeamMember = object['users'] ? object['users'].find((x: any) => !x.archived && x.superUser) : null
          var phoneNumberFormatted = formatPhoneNumberIntl(user?.phoneNumber)
          return phoneNumberFormatted ? phoneNumberFormatted : user?.phoneNumber
        case "emailAddress":
          return object['users'] ? object['users'].find((x: any) => !x.archived && x.superUser)?.emailAddress : ''
        case "approved":
          return object[key] === true ? "Yes": "No"
        case "teamCount":
          return object['users'] ? object['users'].filter((x: any) => !x.archived).length.toString() : "0"
        case "invitedCount":
          return object['invitedUsers'] ? object['invitedUsers'].length.toString() : "0"
        case "actions":
          return (
            <span className="flex">
              <Tooltip content="View company" placement="left">
                <span className="text-lg pt-0.5 pr-4 text-default-600 cursor-pointer active:opacity-50" onClick={() => navigate(`/admin-view-company/${object['uuid']}`)}>
                  <ViewIcon />
                </span>
              </Tooltip>

              {!object['approved'] && (
                <Tooltip content="Approve company" placement="right">
                  <span className="text-lg text-success cursor-pointer active:opacity-50" onClick={() => { setSelectedCompany(object['uuid']); onOpen(); }}>
                    <ApproveIcon />
                  </span>
                </Tooltip>
              )}
            </span>
          )
        default: 
          return object[key];
      }
    }

    const pages = React.useMemo(() => {
      return data?.count ? Math.ceil(data.count / rowsPerPage) : 0;
    }, [data?.count, rowsPerPage]);

    return (
      <>
        <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
          <ModalContent>
            {(onClose) => (
              <>
              <ModalHeader className="flex flex-col gap-1">Approve this company?</ModalHeader>
              <ModalBody>
                  <p> 
                      This will approve the company, allowing the users to post job ads, and interact with candidates.
                  </p>
              </ModalBody>
              <ModalFooter>
                <Button variant="light" onPress={() => { setSelectedCompany(''); onClose() }}>
                  Cancel
                </Button>
                <Button 
                    color="success" 
                    onPress={() => {
                      dispatch(approveCompany({token: keycloak.token, uuid: selectedCompany}))
                        .then((response) => {
                            if(response.meta.requestStatus === 'fulfilled') {
                              setSelectedCompany('');
                            }
                            if(response.meta.requestStatus === 'fulfilled' || response.meta.requestStatus === 'rejected') {
                              onClose()
                            }
                        })
                    }
                }>
                  Approve company
                  {(approveCompanyStatus === 'loading') && <Spinner size="sm" color="default" />}  
                  {(approveCompanyStatus !== 'loading') && <RightArrowIcon />}
                </Button>
              </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
        <Table
          aria-label="Companies"
          isStriped
    
          bottomContent={
            pages > 0 ? (
              <div className="flex w-full justify-center">
                <Pagination
                  isCompact
                  showControls
                  showShadow
                  color="primary"
                  page={page}
                  total={pages}
                  onChange={(page) => setPage(page)}
                />
              </div>
            ) : null
          }
        >
          <TableHeader>
            <TableColumn key="dateCreated">Date joined</TableColumn>
            <TableColumn key="name">Name</TableColumn>
            <TableColumn key="phoneNumber">Phone number</TableColumn>
            <TableColumn key="emailAddress">Email address</TableColumn>
            <TableColumn key="approved">Approved</TableColumn>
            <TableColumn key="teamCount"># of team</TableColumn>
            <TableColumn key="invitedCount"># of invited</TableColumn>
            <TableColumn key="actions">Actions</TableColumn>

          </TableHeader>
          <TableBody
            items={data?.results ?? []}
            emptyContent={"No companies to display."}
            isLoading={isLoading}
            loadingContent={<Spinner label="Loading..." />}
          >
            {(item: Company) => (
              <TableRow key={item?.uuid}>
                {(columnKey) => <TableCell>{getKeyValue(item, columnKey)}</TableCell>}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </>
    );
}
