import {Skeleton} from "@heroui/skeleton";

export default function SelectLoading
() {
    // You can add any UI inside Loading, including a Skeleton.
    return (
        <Skeleton className="w-full rounded-lg">
            <div className="h-14 rounded-lg bg-default-100"></div>
        </Skeleton>
    );
}