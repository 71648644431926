
import {Button} from "@heroui/button"
import {Input, Textarea} from "@heroui/input"
import { Card } from "@heroui/card"
import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks"
import { useKeycloak } from "@react-keycloak/web"
import { getGeolocation, updateProfile } from "../../../../features/profiles/profilesSlice"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { internationalisePhoneNumber, shouldInternationalisePhoneNumber } from "../../../../lib/phoneNumber";
import RightArrowIcon from "../../common/icons/rightArrowIcon"
import { Spinner } from "@heroui/react"
import Autocomplete from "react-google-autocomplete";
import { transformPlace } from "../../../../lib/utils"
import { SearchIcon } from "../../common/icons/searchIcon"

type FormFields = {
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
    idealWorkLocation: any
    description: string
}

export default function InitialCandidateProfileForm() {

    const dispatch = useAppDispatch()
    const { keycloak } = useKeycloak()

    const geolocation = useAppSelector(state => state.profiles.geolocation)

    useEffect(() => {
        if (keycloak.authenticated && !keycloak.userInfo) {
            if(shouldInternationalisePhoneNumber(keycloak.tokenParsed!.phone_number) && !geolocation) {
                dispatch(getGeolocation())
            }
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geolocation])

    return (shouldInternationalisePhoneNumber(keycloak.tokenParsed!.phone_number) && !geolocation) ? null : <InitialCandidateProfileFormActual />
}

function InitialCandidateProfileFormActual() {

    const dispatch = useAppDispatch()
    const { keycloak } = useKeycloak()

    const profile = useAppSelector(state => state.profiles.profile)
    const status = useAppSelector(state => state.profiles.updateProfileStatus)
    const geolocation = useAppSelector(state => state.profiles.geolocation)

    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors },        
    } = useForm<FormFields>({
        mode: 'onBlur',
        defaultValues: {
            firstName: keycloak?.tokenParsed?.given_name,
            lastName: keycloak?.tokenParsed?.family_name,
            email: keycloak?.tokenParsed?.email,
            phoneNumber: internationalisePhoneNumber(keycloak?.tokenParsed?.phone_number, geolocation),
            idealWorkLocation: '',
            description: profile?.candidateProfile?.description
        }
    })

    const onSubmit: SubmitHandler<FormFields> = (data: any) => {
        dispatch(updateProfile({token: keycloak.token, uuid: profile?.uuid, payload: data}))
    }

    return (
        <div className="w-full min-h-screen bg-zinc-50 content-center">

            <h1 className="text-center text-2xl font-bold w-full pt-12 xl:pt-0">{keycloak.tokenParsed?.given_name}, tell us more about yourself</h1>

            <div className="container mx-auto pt-24 pb-24">
                <Card className="w-10/12 sm:w-full max-w-2xl p-8 mx-auto">
                    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-row flex-wrap w-full justify-between">
                            <div className="w-full">
                                <div className="flex flex-wrap mb-3 lg:mb-6">
                                    <div className="w-full mb-3 lg:w-1/2 lg:mb-0 lg:pr-6">
                                        <Input
                                            label="First name"
                                            placeholder="Enter your first name"
                                            isRequired={true}
                                            onClear={() => console.log("clear")}
                                            errorMessage={errors.firstName && "First name is required"}
                                            isInvalid={errors.firstName != null}
                                            {...register("firstName", {required: true})} />
                                    </div>
                                    <div className="w-full lg:w-1/2">
                                        <Input
                                            label="Last name"
                                            placeholder="Enter your last name"
                                            isRequired={true}
                                            onClear={() => console.log("clear")}
                                            errorMessage={errors.lastName && "Last name is required"}
                                            isInvalid={errors.lastName != null}
                                            {...register("lastName", {required: true})} />
                                    </div>
                                </div>
                                <div className="flex flex-wrap mb-3 lg:mb-6">
                                    <div className="w-full mb-3 lg:w-1/2 lg:mb-0 lg:pr-6">
                                        <div className={`controller-wrapper bg-default-100 rounded-medium${errors?.phoneNumber ? ' error' : ''}`}>
                                            <label className="z-10 pointer-events-none subpixel-antialiased block text-foreground-500 cursor-text after:content-['*'] after:text-danger after:ml-0.5 text-small">Phone number</label>
                                            <Controller
                                                control={control}
                                                name="phoneNumber"
                                                rules={{
                                                    validate: {
                                                        required: (value) => {
                                                            if (!value) return 'Phone number is required';
                                                            if (!isValidPhoneNumber(value)) return 'A valid phone number is required';
                                                        }
                                                    }
                                                }}
                                                render={({ field: { onChange, onBlur, value } }) => (
                                                    <PhoneInput
                                                        className="phone-input"
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        defaultCountry="AU"
                                                        placeholder="Enter phone number"
                                                    />
                                                )}
                                            />
                                        </div>
                                        {errors?.phoneNumber && <span className="controller-error">{errors.phoneNumber.message}</span>}
                                    </div>
                                    <div className="w-full lg:w-1/2">
                                        <Input
                                            label="Email address"
                                            placeholder="Enter your email address"
                                            isRequired={true}
                                            isDisabled={true}
                                            onClear={() => console.log("clear")}
                                            errorMessage={errors.email && "Email address is required"}
                                            isInvalid={errors.email != null}
                                            {...register("email", {required: true})} />

                                    </div>
                                </div>
                                <div className="w-full">

                                <div className={`controller-wrapper bg-default-100 rounded-medium${errors?.idealWorkLocation ? ' error' : ''}`}>
                                        <label className="z-10 pointer-events-none subpixel-antialiased block text-foreground-500 cursor-text after:content-['*'] after:text-danger after:ml-0.5 text-small">Ideal work Location</label>
                                        <Controller
                                            control={control}
                                            name="idealWorkLocation"
                                            rules={{
                                                validate: {
                                                    required: (value) => {
                                                        if (!value) return 'A valid location is required, please select from the list';
                                                    }
                                                }
                                            }}
                                            render={({ field: { value } }) => (

                                                <div className="flex">
                                                    <SearchIcon className="mt-1 mr-2 text-default-400" strokeWidth={2.5} size={14} />
                                                    <Autocomplete
                                                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                                        options={{
                                                            fields: ['geometry', 'name', 'formatted_address', 'types'],
                                                            types: ['country', 'locality', 'administrative_area_level_1', 'administrative_area_level_2', 'administrative_area_level_3']
                                                        }}
                                                        onPlaceSelected={(place) => {
                                                            setValue('idealWorkLocation', transformPlace(place), { shouldValidate: true, shouldTouch: true, shouldDirty: true})
                                                        }} 
                                                        onBlur={(event) => {
                                                            // If we arrived here by clicking a selection, ignore this
                                                            var pacContainers = document.getElementsByClassName('pac-container');
                                                            if(pacContainers.length === 0) {
                                                                return;
                                                            }
                                                            var pacContainer = pacContainers[0];
                                                            if(pacContainer && pacContainer.matches(':hover')) {
                                                                return
                                                            }
                
                                                            setValue('idealWorkLocation', null, { shouldTouch: true, shouldDirty: true})
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                    {errors?.idealWorkLocation && <span className="controller-error">{errors.idealWorkLocation?.message?.toString()}</span>}

                                    <Textarea
                                        label="About you"
                                        minRows={5}
                                        placeholder="Tell us about yourself"
                                        isRequired={true}
                                        onClear={() => console.log("clear")}
                                        className="ml-auto w-full mt-6 mb-6 lg:mb-12"
                                        errorMessage={errors.description && "Description is required"}
                                        isInvalid={errors.description != null}
                                        {...register("description", {required: true})} />

                                </div>    
                            </div>
                        </div>
                        <div className="flex flex-wrap w-full pt-6">
                            <Button color="primary" type="submit">
                                Continue
                                {status === 'loading' && <Spinner size="sm" color="default" />} 
                                {status !== 'loading' && <RightArrowIcon />}
                            </Button>
                            {status === 'failed' && <span className="form-error block md:inline">Error received from the server, please try again</span>}
                        </div>

                    </form>
                </Card>
            </div>
        </div>
    )
};