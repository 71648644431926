import { Button, Card, CardBody, CardFooter, CardHeader, Input, Spinner } from "@heroui/react";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/hooks";
import { enterInvitationCode } from "../../../../features/profiles/profilesSlice";
import { useKeycloak } from "@react-keycloak/web";
import { platformName } from "../../../../lib/utils";
import { invitationCodeRequired, isCandidateUser } from "../../../../lib/user";

export default function CandidateInvitationCode() {

    const dispatch = useAppDispatch()
    const { keycloak } = useKeycloak()

    const profile = useAppSelector(state => state.profiles.profile)
    const status = useAppSelector(state => state.profiles.enterInvitationCodeStatus)

    const [value, setValue] = useState("");
    const [invalid, setInvalid] = useState(false);

    const handleSubmit = () => {
        if(!value) {
            setInvalid(true);
        } else {
            setInvalid(false);
            dispatch(enterInvitationCode( {token: keycloak.token!, userId: keycloak.tokenParsed?.sub, uuid: value}));
        }
    }

    return (isCandidateUser(keycloak) && invitationCodeRequired(keycloak) && !profile?.invited) ? (

        <div className="w-100 min-h-dvh flex text-center justify-center items-center">
            
            <Card className="w-10/12 sm:w-full max-w-lg p-4 mx-auto">
                <CardHeader className="flex flex-col">
                    <h1 className="text-xl font-bold mb-12 w-full">
                        Welcome to {platformName()}, {keycloak.tokenParsed?.given_name}
                    </h1>
                    <h2 className="text-md mb-6 px-8">
                        As we are currently in an invite only phase, you'll need to provide your unique code to continue.
                    </h2>
                </CardHeader>
                <CardBody className="px-12">
                    <Input 
                        isRequired 
                        isInvalid={status === 'failed' || invalid}
                        errorMessage="Please enter a valid invitation code"
                        value={value}
                        variant="bordered"
                        onValueChange={setValue} 
                        type="text" 
                        label={`${platformName()} code`}
                        placeholder="Enter your invitation code" />

                </CardBody>
                <CardFooter>
                    <div className="text-center pt-12 w-full">
                        <Button color="primary" type="submit"
                                onClick={() => handleSubmit()}>
                                {status === 'loading' && <Spinner color="default" size="sm" />}
                                Continue
                        </Button> 
                    </div>
                </CardFooter>
            </Card>
        </div>
    ): null

}